import React, {useState, useRef, Fragment, useEffect} from "react";
import {
   Row,
   Col,
   Card,
   Table,
   Badge,
   Dropdown,
   ProgressBar,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import PageTitle from "../../../../layouts/PageTitle";
import DynamicDataTable from "@langleyfoxall/react-dynamic-data-table";
import DynamicContextMenu from "@langleyfoxall/react-dynamic-data-table";
import {useDispatch, useSelector} from "react-redux";
import {getOneUserEffect, usergetEffect} from "../../../../../../store/effects/UserEffects";
import {useTranslation} from "react-i18next";

const UsersDetails = (props) => {
   const dispatch = useDispatch()
   const id = (props.match.params.id !== undefined && props.match.params.id !== null) ? props.match.params.id : null;
   const usersState = useSelector((state) => state.users)
   const [user, setUser] = useState(undefined)
   const {t} = useTranslation()
   const checked = new Set

   const svg1 = (
       <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
             <rect x="0" y="0" width="24" height="24"></rect>
             <circle fill="#000000" cx="5" cy="12" r="2"></circle>
             <circle fill="#000000" cx="12" cy="12" r="2"></circle>
             <circle fill="#000000" cx="19" cy="12" r="2"></circle>
          </g>
       </svg>
   );


   useEffect(() => {

      if (id !== null && typeof parseInt(id) === "number") {
         getOneUserEffect(id).then((r) => {
            console.log('R', r)
            setUser(r)

         }).catch(err => console.log(err));
      }

   }, [])

   const renderValues = (values) => {
      let component = [];
      for (let key in values) {

         if (values.hasOwnProperty(key)) {
            switch (key) {
               case 'profil':

                  component.push({"key":key,"values":values[key].label})
                  break;

               case 'userAccountCreationRequests':

                  break;
               case 'imageUrl':

                  break;

               default:
                  console.log(`${key}: ${values[key]}`);
                  component.push({"key":key,"values":values[key]})
            }

         }

      }
     return component
   }


   return (
       <div className="col-12 ">
          <PageTitle activeMenu="Details" motherMenu="Users"/>
          <Fragment>
             <Row className={"centerpage"}>
                <Col lg={12}>
                   <Card>
                      <Card.Header>

                         {
                            <Link to={"/admin/users/edit/"+id}  className="btn btn-sm btn-primary" > <i className="fa fa-edit"></i> Edit</Link>

                            //<Card.Title>Recent Payments Queue</Card.Title>  onClick={()=>{}} className="btn btn-sm btn-primary" data-toggle="modal" data-target="#addOrderModalside"
                         }
                      </Card.Header>

                      <Card.Body>
                         <Table
                             responsive
                             bordered
                         >

                            <tbody>

                            {
                               user !== undefined && renderValues(user).map(value => <tr><td width={100}><b>{t(value.key)}</b> </td><td>{value.values} </td></tr>)
                            }

                            </tbody>
                         </Table>
                      </Card.Body>
                   </Card>
                </Col>
             </Row>
          </Fragment>

       </div>
   );

}

export default UsersDetails;
