import React, { useState, useRef, useEffect,Fragment} from "react";
import {connect, Provider, useDispatch, useSelector} from 'react-redux';
import {
   Row,
   Col,
   Card,
   Table,
   Button,
   Alert,
   Badge,
   Dropdown,
   ProgressBar,
} from "react-bootstrap";

import { useTranslation } from 'react-i18next';
import PageTitle from "../../../../layouts/PageTitle";
import {getOneRangeEffect, getRangeEffect, profileAddEffect, profileUpdateEffect, rangeAddEffect, rangeUpdateEffect} from "../../../../../../store/effects/FormEffects";
import {getRolesEffect} from "../../../../../../store/effects/UserEffects";
import { Link } from "react-router-dom";


const RangesForm = (props) => {

   const { t} = useTranslation()
   const id = (props.match.params.id!== undefined && props.match.params.id !==null)?props.match.params.id:null;
   
   const dispatch = useDispatch()
   const userState = useSelector((state) => state.users)
   const rolesState = useSelector((state) => state.roles.roles)
   const formrequestState = useSelector((state) => state.formRequest)

   const [formData, setFormData] = useState({
      name: "",
      description: ""
   });

   const handleBlur = (e) => {
      const newFormData = { ...formData };
      
      newFormData[e.target.name] = e.target.value;
      setFormData(newFormData);
      
   };


   const svg1 = (
      <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
         <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
         </g>
      </svg>
   );

   const submitHandler = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity()===false) {
         event.preventDefault()
         event.stopPropagation()
      }else{
         event.preventDefault();
         (id==null)?dispatch(rangeAddEffect(formData, props)):dispatch(rangeUpdateEffect(formData,id,props))
      }

      form.classList.add('was-validated');

   };


   useEffect(()=>{

      if (id!==null){
         getOneRangeEffect(id).then(r=>{
            const tmpgeted = { ...formData };
            for (const [key, value] of Object.entries(r)) {

               tmpgeted[key]= value;
            }

            setFormData(tmpgeted)
         }).catch(e=>{

         })
      }
   }, [])

   return (
      <div className="col-12">
      <PageTitle activeMenu="Edit" motherMenu="Product Categories" />
         <div className="card">
            <div className="card-header">
              <div style={{width:"90%"}}>
                 <h3>Ranges Editing</h3>
              </div>
              <Link to="/admin/ranges" className="btn btn-primary btn-xs"><i className="fa fa-arrow-left"></i> Back</Link>
           
            </div>
            <div className="card-body pl-5">

               <Fragment>
                  
                  <Row>
                     <Col lg={6}>
                        <div className="basic-form">
                           <form class="row g-3 needs-validation" onSubmit={submitHandler} noValidate>
                              <div className="form-row">
                                 <div className="form-group col-md-12">
                                    <label>Name <span style={{color:"red"}}>*</span></label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       placeholder="range name"
                                       onChange={handleBlur}
                                       required
                                       value={formData.name}
                                       name='name'
                                    />
                                    <div class="invalid-feedback">
                                       Please enter a name
                                    </div>
                                 </div>
                                 <div className="form-group col-md-12">
                                    <label>Description <span style={{color:"red"}}>*</span></label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       placeholder=" description"
                                       requied
                                       value={formData?.description}
                                       name='description'
                                       onChange={handleBlur}
                                    />
                                    <div class="invalid-feedback">
                                       Please enter a description
                                    </div>
                                 </div>

                              </div>
                              
                              <div className="form-group col-md-12">

                              </div>{
                              ()=>{console.log('userstate',userState )}
                           }
                              <button type="submit" id="submit"  className="btn btn-primary">
                                 
                                 {

                                     (formrequestState.form_loading) ?<>
                                     <span class="spinner-grow spinner-grow-sm"  role="status" aria-hidden="true"></span>
                                     <span class=""> Loading... </span></> : <span> {(id==null)?'Create':'Save'} </span> 
                                 }
                              </button>
                           </form>
                        </div>
                     </Col>
                  </Row>
               </Fragment>
   
            </div>
         </div>
      </div>
   );
};

export default RangesForm;
