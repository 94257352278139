import { Action } from "redux";
import { GET_SESSION } from "../store/actions/AccountAction";
import {
    GET_USER_POPROFILE,
    GET_USER_ROLES,
    LOGIN_FAILURE,
    LOGIN_LOADING,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    SIGNUP_FAILURE,
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    USER_FAILURE,
    USER_REQUEST,
    USER_SUCCESS
} from "../store/types/AccountTypes";
import {FORM_REQUEST, FORM_STOP} from "../store/types/RequestTypes";

export interface Customer {
    id: string,
    first_name: string,
    last_name: string,
    email: string,
    phone: string,
}

export interface Session {
    id:       number;
    username: string;
    email:    string;
    profil?:   Profil;
    type:     string;
    token:    string;
    expireIn?: Date;
}

export interface User {
    id:                          number;
    userName:                    string;
    firstName:                   string;
    lastName:                    string;
    email:                       string;
    langKey:                     string;
    imageUrl:                    null;
    profil:                      Profil;
    userAccountCreationRequests?: any[];
}

export interface Profil {
    id:          number;
    label:       string;
    description: string;
    roles?:       Role[];
}

export interface Role {
    id:     number;
    resourcePath: string;
    method: string;
}

// Converts JSON strings to/from your types
export class Convert {
    public static toUser(json: string): User {
        return JSON.parse(json);
    }

    public static userToJson(value: User): string {
        return JSON.stringify(value);
    }

    public static toSession(json: string): Session {
        return JSON.parse(json);
    }

    public static sessionToJson(value: Session): string {
        return JSON.stringify(value);
    }

}




export interface GetSession extends Action {
    type: typeof GET_SESSION;
    access_token: string;
    token_type: string;
    user: User | undefined;
    customer: Customer | undefined;
}

export interface SignUpRequest extends Action {
    type: typeof SIGNUP_REQUEST;
  }
  
export interface SignUpSuccess extends Action {
    type: typeof SIGNUP_SUCCESS;
    message: string
}
  
export interface SignUpError extends Action {
    type: typeof SIGNUP_FAILURE;
    message: string;
}

export interface SetLoginLoading extends Action {
    type: typeof LOGIN_LOADING;
    isloading: boolean;
}

export interface LoginRequest extends Action {
    type: typeof LOGIN_REQUEST;
  }


export interface LoginSuccess extends Action {
    type: typeof LOGIN_SUCCESS;
    session?: Session | undefined;
}
  
export interface LoginError extends Action {
    type: typeof LOGIN_FAILURE;
    message: string;
}


export interface UserRequest extends Action {
    type: typeof USER_REQUEST;
  }

export interface FormRequest extends Action {
    type: typeof FORM_REQUEST;
}

export interface FormStop extends Action {
    type: typeof FORM_STOP;
}



export interface UserSuccess extends Action {
    type: typeof USER_SUCCESS;
    user: User[];
}
  
export interface UserError extends Action {
    type: typeof USER_FAILURE;
    message: string;
}

export interface GetProfile extends Action {
    type: typeof GET_USER_POPROFILE;
    profile: Profil[];
}

export interface GetRoles extends Action {
    type: typeof GET_USER_ROLES;
    roles: Role[];
}