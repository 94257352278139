/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { Component, Fragment, lazy, Suspense } from 'react'
import { RouteConfig } from 'react-router-config'
import { Switch, Redirect, Route } from 'react-router-dom';
import {useSelector} from "react-redux";
import {ApplicationState} from "../../store/types";
import NavigationBar from "../../views/Common/NavBar";
import Footer from "../../views/Common/Footer";
import '../../App.css'
import {Container, Row} from "react-bootstrap";
import  backgroundimage from '../../assets/images/background_image.jpg'
function GuestGuard(props: any) {
    const { children } = props
    return children
}


const routesConfig: RouteConfig[] = [
    {
        path: '/',
        guard: GuestGuard,
        routes: [
            {
                path: '/',
                exact: true,
                component: lazy(() => import('../../views/Home/Home'))
            },
            {
                path: '/create-account',
                exact: true,
                component: lazy(() => import('../../views/Form/Forms'))
            },
            {
                path: '/create-account/:step',
                exact: true,
                component: lazy(() => import('../../views/Form/Forms'))
            },
           
        ]
    }
];

const renderRoutes = (routes: RouteConfig[]) =>
  routes ? (
    // <Suspense fallback={<LoadingScreen />}>
    <Suspense fallback={() => {<div/>} }>

        <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component: any = route.component;
          return (
            <Route

              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <div>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </div>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return <div  id="container-background" style={{backgroundImage: `url(${backgroundimage})`}}>
            <NavigationBar/>
            {renderRoutes(routesConfig)}
            <Footer />
        </div>;
}

export default Routes;
