import React, { Component } from "react";
import { Line } from "react-chartjs-2";

const data = {
   defaultFontFamily: "Poppins",
   labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
   datasets: [
      {
        label: "Sales Stats",
		backgroundColor: ['rgba(234, 73, 137, 0)'],
		borderColor: '#00589B',
		pointBackgroundColor: '#00589B',
		pointBorderColor: '#00589B',
		borderWidth:6,
		borderRadius:10,
		pointHoverBackgroundColor: '#00589B',
		pointHoverBorderColor: '#00589B',
		data: [20, 10, 20, 10, 25, 20, 35, 30]
      },
   ],
};

const options = {
	title: {
		display: !1
	},
	tooltips: {
		intersect: !1,
		mode: "nearest",
		xPadding: 10,
		yPadding: 10,
		caretPadding: 10
	},
   legend: {
		display: !1
	},
	responsive: !0,
	maintainAspectRatio: !1,
	hover: {
		mode: "index"
	},
   scales: {
      yAxes: [{
		display: !1,
		gridLines: !1,
		scaleLabel: {
			display: !0,
			labelString: "Value"
		},
		ticks: {
			beginAtZero: !0
		}
	}]
   },
   elements: {
		point: {
			radius: 0,
			borderWidth: 0
		}
	},
	layout: {
		padding: {
			left: 0,
			right: 0,
			top: 5,
			bottom: 0
		}
	}
};
class Linechart extends Component {
   render() {
      return (
         <>
            <Line data={data} options={options} height={150} />
         </>
      );
   }

   // componentDidMount() {
   //     const { datasets } = this.refs.chart.chartInstance.data
   //     console.log(datasets[0].data);
   // }
}

export default Linechart;
