import produce from 'immer';
import SecureLS from 'secure-ls';
import {Convert, Session, User} from '../../../interfaces';
import { GET_SESSION } from '../../actions/AccountAction';
import { ApplicationAction } from '../../types';
import {  AccountState, GET_USER_POPROFILE, LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, UserState, USER_FAILURE, USER_REQUEST, USER_SUCCESS, GET_USERS } from '../../types/AccountTypes'


const ls = new SecureLS({ encodingType: 'aes' });

let session  = ls.get('session') 

if (session !==undefined  && session !=='')
  session = Convert.toSession(session);
  
else
  session = {
    id:0,
    username: '',
    email:    '',
    profil: undefined,
    type:'',
    token:'',
    expireIn: undefined
  }

const initialState: UserState = {
    user: undefined,
    loading: {
        user_loading: false,
        user_failed: false,
        user_failed_message: '',
    }
}



const reducer = (state = initialState, action: ApplicationAction) => {
    switch (action.type) {
      case LOGIN_REQUEST: {
        return produce(state, (draft: {  session: undefined; loading: { login_loading: boolean; login_failed: boolean; login_failed_message: string; }; }) => {

          draft.session = undefined;
          draft.loading.login_loading = true;
          draft.loading.login_failed = false;
          draft.loading.login_failed_message = '';
        });
      }
  
      case LOGIN_SUCCESS: {
        const { session } = action;
        console.log(session)
        ls.set('session', (session===undefined)?"":Convert.sessionToJson(session));
        
        return produce(state, (draft: { session: Session; loading: { login_loading: boolean; login_failed: boolean; login_failed_message: string; }; }) => {
          if (session) {
            draft.session = session;
          }

          draft.loading.login_loading = false;
          draft.loading.login_failed = false;
          draft.loading.login_failed_message = '';
         
        });
      }
  
      case LOGIN_FAILURE: {
        const { message } = action;
  
        return produce(state, (draft: { loading: { login_loading: boolean; login_failed: boolean; login_failed_message: string; }; }) => {
          draft.loading.login_loading = false;
          draft.loading.login_failed = true;
          draft.loading.login_failed_message = message;
        });
      } 
      
      case USER_SUCCESS: {
        const { user } = action;
  
        return produce(state, (draft) => {


          draft.user = user;
          draft.loading.user_loading = false;
          draft.loading.user_failed = false;
          draft.loading.user_failed_message = '';
        });
      }

      case USER_FAILURE: {
        const { message } = action;
  
        return produce(state, (draft) => {
          draft.loading.user_loading = false;
          draft.loading.user_failed = true;
          draft.loading.user_failed_message = message;
        });
      }

      case USER_REQUEST: {
      
        return produce(state, (draft) => {
          draft.user = undefined;
          
          draft.loading.user_loading = true;
          draft.loading.user_failed = false;
          draft.loading.user_failed_message = '';
        });
      }
    
      default:
          return state
    }
}  

export default reducer