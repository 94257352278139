import produce from 'immer';
import SecureLS from 'secure-ls';
import { Convert, Session } from '../../../interfaces';
import { GET_SESSION } from '../../actions/AccountAction';
import { ApplicationAction } from '../../types';
import {
    AccountState,
    GET_USER_POPROFILE,
    LOGIN_FAILURE,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    ProfilState,
    UserState,
    USER_FAILURE,
    USER_REQUEST,
    USER_SUCCESS,
    GET_USER_ROLES, RoleState
} from '../../types/AccountTypes'


const ls = new SecureLS({ encodingType: 'aes' });




const initialState: RoleState = {
    roles: undefined
}

const reducer = (state = initialState, action: ApplicationAction) => {
    switch (action.type) {
        case GET_USER_ROLES: {
            return produce(state, (draft) => {
                const { roles } = action;
                // Ensure we clear current session
                draft.roles = roles;
            });
        }
    
      default:
          return state
    }
}  

export default reducer