import {Button, Col, Container, Form, FormControl, Nav, Navbar, NavDropdown, Row} from "react-bootstrap";
import React from "react";
import logo from '../../assets/images/logo-bgfi.png'
import NavigationBar from "./NavBar";
import {Link} from "react-router-dom";
import {FaFacebook, FaTwitter} from "react-icons/all";


export default function Footer() {
    
    return(
        <section className="eer-footer pt-5 pb-5">
            <Container>
                <Row>
                    <Col md={3} xs={12}>
                        <b>Nos comptes</b>
                        <ul>
                            <li><Link to="#">Particulier</Link></li>
                            <li><Link to="#">Entreprise</Link></li>
                            <li><Link to="#">Compte join</Link></li>
                        </ul>
                    </Col>
                    <Col md={3} xs={12}>
                        <b>Liens utiles</b>
                        <ul>
                            <li><Link to="#">Groupe BGFI Bank</Link></li>
                            <li><Link to="#">Réseau d’agences</Link></li>
                            <li><Link to="#">Actualité</Link></li>
                        </ul>
                    </Col>
                    <Col md={3} xs={12}>
                        <b>Suivez nous </b>
                        <ul>
                            <li><FaFacebook/> <Link to="#">Facebook</Link></li>
                            <li><FaTwitter /> <Link to="#">Twitter</Link></li>
                        </ul>
                    </Col>
                    <Col md={3} xs={12}>

                    </Col>
                </Row>
            </Container>

        </section>

    )
}