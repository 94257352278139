import React from 'react';
import {
    BrowserRouter as Router, Redirect, Route, Switch
} from "react-router-dom";
import { AnyAction, Store } from 'redux';
import Admin from './admin/App'
import Routes from './components/routes/Routes';
import { ApplicationState } from './store/types';
import {connect, Provider, useSelector} from 'react-redux';
import Login from "./admin/jsx/pages/Login";
import ForgotPassword from "./admin/jsx/pages/ForgotPassword";




function AuthGuard(props: any) {
    const { children } = props;
    const account = useSelector((state: ApplicationState) => state.account);
    const is_auth =  (account.session && account.session.token.length>0)?true:false;
    if (!is_auth) {
        return <Redirect to="/admin/login" />;
    }

    return children;
}

function IsAuthGuard(props: any) {
    const { children } = props;
    const account = useSelector((state: ApplicationState) => state.account);
    const is_auth =  (account.session && account.session.token.length>0)?true:false;
    if (is_auth) {
        return <Redirect to="/admin/" />;
    }

    return children;
}

function App( { store }: { store: Store<ApplicationState, AnyAction> } ) {
  return (
    <Provider store={store}>
      <div className="App">
        <Router basename='/'>
            <Switch>

                <Route exact path="/admin/login">
                    <IsAuthGuard><Login/></IsAuthGuard>
                </Route>
                <Route exact path="/admin/page-forgot-password">
                    <IsAuthGuard><ForgotPassword/></IsAuthGuard>
                </Route>
                <Route path="/admin/">
                    <AuthGuard><Admin/></AuthGuard>
                </Route>
                
                <Route path="/">
                    <Routes/>
                </Route>

            </Switch>

        </Router>
      </div>
    </Provider>
  );
}

const mapStateToProps = (state: ApplicationState) =>{
    
    return {
        session:state.account
        
    }}

const mapDispatchToProps = (state:ApplicationState) =>{
   
    return {}}

export default connect(mapStateToProps,mapDispatchToProps)(App)
