import React, { useState, useRef, useEffect,Fragment} from "react";
import {connect, Provider, useDispatch, useSelector} from 'react-redux';
import {
   Row,
   Col,
   Card,
   Table,
   Button,
   Alert,
   Badge,
   Dropdown,
   ProgressBar,
} from "react-bootstrap";

import { useTranslation } from 'react-i18next';
import PageTitle from "../../../../layouts/PageTitle";
import {agenciesAddEffect, getRegionsEffect, profileAddEffect, profileUpdateEffect} from "../../../../../../store/effects/FormEffects";
import {getRolesEffect} from "../../../../../../store/effects/UserEffects";


const AgenciesForm = (props) => {

   const { t} = useTranslation()
   const id = (props.match.params.id!== undefined && props.match.params.id !==null)?props.match.params.id:null;
   const dispatch = useDispatch()
   const userState = useSelector((state) => state.users)
   const rolesState = useSelector((state) => state.roles.roles)
   const formrequestState = useSelector((state) => state.formRequest)

   const [regions, setRegions] = useState([])
      
   const [formData, setFormData] = useState({
      name: "",
      phoneNumber1: "",
      phoneNumber2: "",
      address:0
   });

   const [addressFormData, setAddressFormData] = useState({

      town: "",
      street: "",
      localisation: "",
      postalCode: "",
      region: 0
   });
   
   const handleBlur = (e) => {
      const newAgenciesFormData = { ...formData };
      newAgenciesFormData[e.target.name] = e.target.value;
      setFormData(newAgenciesFormData);
   };


   const handleBlurAddress = (e) => {
      const newAgenciesAddressFormData = { ...addressFormData };
      newAgenciesAddressFormData[e.target.name] = e.target.value;
      setAddressFormData(newAgenciesAddressFormData);     
   };

   const svg1 = (
      <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
         <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
         </g>
      </svg>
   );

   const submitHandler = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity()===false) {
         event.preventDefault()
         event.stopPropagation()
      }else{
         event.preventDefault();
         (id==null)?dispatch(agenciesAddEffect(formData, addressFormData, props)):dispatch(agenciesAddEffect(formData,addressFormData,props))
      }

      form.classList.add('was-validated');

   };


   useEffect(()=>{
      getRegionsEffect().then((r)=>{
        setRegions(r)
      })

      if (id!==null){

      }
   }, [])

   return (
      <div className="col-12">
      <PageTitle activeMenu="Edit" motherMenu="Agencies" />
         <div className="card">
            <div className="card-header">
              <div style={{width:"100%"}}>
                 <h3>Agency Editing</h3>
              </div>
            </div>
            <div className="card-body pl-5">

               <Fragment>
                  
                  <Row>
                     <Col lg={6}>
                        <div className="basic-form">
                           <form class="row g-3 needs-validation" onSubmit={submitHandler} noValidate>
                              <div className="form-row">
                                 <div className="form-group col-md-12">
                                    <label>Agency name <span style={{color:"red"}}>*</span></label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       placeholder="agency name"
                                       onChange={handleBlur}
                                       required
                                       name='name'
                                    />
                                    <div class="invalid-feedback">
                                       Please enter a name for this agency
                                    </div>
                                 </div>
                                 
                                 <div className="form-group col-md-12">
                                    <label>Primary phone number <span style={{color:"red"}}>*</span></label>
                                    <div class="input-group mb-3">
                                       <span class="input-group-text" id="inputGroup-sizing-default">+237</span>
                                       <input
                                          type="text"
                                          className="form-control"
                                          placeholder=""
                                          required
                                          minLength={9}
                                          maxLength={9}
                                          name='phoneNumber1'
                                          onChange={handleBlur}
                                       />
                                       <div class="invalid-feedback">
                                          Please enter a valid phone number
                                       </div>
                                    </div>
                                    
                                    
                                 </div>

                                 <div className="form-group col-md-12">
                                    <label>Secondary phone number </label>
                                    <div class="input-group mb-3">
                                       <span class="input-group-text" id="inputGroup-sizing-default">+237</span>
                                       <input
                                       type="text"
                                       minLength={9}
                                          maxLength={9}
                                       className="form-control"
                                       placeholder=""
                                       name='phoneNumber2'
                                       onChange={handleBlur}
                                    />
                                       <div class="invalid-feedback">
                                          Please enter a valid phone number
                                       </div>
                                 </div>
                                    
                                 </div>
                                 <div className="form-group col-md-12">
                                    <label>Agency City <span style={{color:"red"}}>*</span></label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       placeholder="agency name"
                                       onChange={handleBlurAddress}
                                       required
                                       name='town'
                                    />
                                    <div class="invalid-feedback">
                                       Please enter a city name for this agency
                                    </div>
                                 </div>

                                 <div className="form-group col-md-12">
                                    <label>Agency Street <span style={{color:"red"}}>*</span></label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       placeholder="agency name"
                                       onChange={handleBlurAddress}
                                       required
                                       name='street'
                                    />
                                    <div class="invalid-feedback">
                                       Please enter a street for this agency
                                    </div>
                                 </div>

                                 <div className="form-group col-md-12">
                                    <label>Agency Localisation <span style={{color:"red"}}>*</span></label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       placeholder="agency name"
                                       onChange={handleBlurAddress}
                                       required
                                       name='localisation'
                                    />
                                    <div class="invalid-feedback">
                                       Please enter a location for this agency
                                    </div>
                                 </div>
                                 <div className="form-group col-md-12">
                                    <label>Postal code <span style={{color:"red"}}>*</span></label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       placeholder="agency name"
                                       onChange={handleBlurAddress}
                                       required
                                       name='postalCode'
                                    />
                                    <div class="invalid-feedback">
                                       Please enter a postal code
                                    </div>
                                 </div>

                                 <div className="form-group col-md-12">
                                    <label>Agency region <span style={{color:"red"}}>*</span></label>
                                    
                                    <select class="form-control form-select" required name='region' onChange={handleBlurAddress}>
                                       <option value=""> -- select -- </option>
                                       {
                                          regions!==undefined && regions.length > 0 && regions.map((v)=>{
                                             return <option value={v.id}>{v.name}</option>
                                          })
                                       }
                                    </select>
                                    
                                    <div class="invalid-feedback">
                                       Please pick a region for this agency
                                    </div>
                                 </div>

                                 
                              </div>
                              
                              <div className="form-group col-md-12">

                              </div>{
                              ()=>{console.log('userstate',userState )}
                           }
                              <button type="submit" id="submit"  className="btn btn-primary">
                                 
                                 {

                                     (formrequestState.form_loading) ?<>
                                     <span class="spinner-grow spinner-grow-sm"  role="status" aria-hidden="true"></span>
                                     <span class=""> Loading... </span></> : <span> Create </span> 
                                 }
                              </button>
                           </form>
                        </div>
                     </Col>
                  </Row>
               </Fragment>
   
            </div>
         </div>
      </div>
   );
};

export default AgenciesForm;
