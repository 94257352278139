import { UseTranslationOptions } from 'react-i18next';
import { Action } from 'redux'
import { Customer, Profil, Session, User } from '../../interfaces';


export const FORM_REQUEST = 'FORM_REQUEST';
export const FORM_STOP = 'FORM_STOP';



export interface LoadingFormState {
    form_loading: boolean;
    form_failed: boolean;
    form_failed_message: string;
}


