export default {
    translation: {
        admin:{
            hello:"Hello"
        },
        PUT:"Can edit ",
        POST:"Can create ",
        DELETE:"Can delete ",
        GET:"Can read ",
        PATCH:"Can partial edit ",
        hello:"Hello",
        agencies:"Agencies",
        "on.waiting.folder":"On waiting documents",
        customers:"Customers",
        users:"Users",
        done:"Done",
        sorry:"Sorry",
        success_add_user:"User added successfully",
        success_editing:"Data saved successfully ",
        error_editing:"An error occurred while saving data, try again later please",
        error_deleting:"An error occurred while deleting data or the item has been assign to existing resource",
    }
}