import { UseTranslationOptions } from 'react-i18next';
import { Action } from 'redux'
import {Customer, Profil, Role, Session, User} from '../../interfaces';


export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_LOADING = 'LOGIN_LOADING';


export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';


export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';
export const USER_LOADING = 'USER_LOADING';

export const GET_USER_POPROFILE= 'GET_USER_POPROFILE';

export const GET_USER_ROLES= 'GET_USER_ROLES';

export const GET_USERS= 'GET_USERS';


export interface LoadingAccountState {
    login_loading: boolean;
    login_failed: boolean;
    login_failed_message: string;
}

export interface LoadingUserState {
    user_loading: boolean;
    user_failed: boolean;
    user_failed_message: string;
}

export interface AccountState {
    loading: LoadingAccountState;
    session?: Session
}

export interface UserState {
    loading: LoadingUserState;
    user?: User[]
}
export interface ProfilState {
    profile?: Profil[]
}

export interface RoleState {
    roles?: Role[]
}
