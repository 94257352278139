import React,{Fragment,useState} from "react";
import { Link } from "react-router-dom";
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';
import productData from "../productData";
import PageTitle from "../../../../layouts/PageTitle";
import {Modal} from 'react-bootstrap';
import Ratingpage from '../ProductList/Ratingpage';
import avatar from '../../../../../images/avatar/1.jpg';

const ProductDetail = ({ match }) => {
   //** Dynamic Key Single Place */
   const singleProductData = productData.find((sinPl) =>
      sinPl.key === match.params.id ? match.params.id : productData[0].key
   );
   const {
      
      rating,
      price,
      availability,
      productCode,
      brand,
      tags,
      des,
   } = singleProductData;
    	
	const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
		
        if (activeTab !== tab) {
			setActiveTab(tab);
		}
    }
	
	/// Modal
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	
   return (
	<Fragment>
      <div className="h-80">
        
		<PageTitle headingPara= "Your business dashboard template" activeMenu="Blank" motherMenu="Layout" />
         <div className="row">
            <div className="col-lg-12">
               <div className="card">
                  <div className="card-body">
                     <div className="row">
						<div className="col-xl-3 col-lg-6  col-md-6 col-xxl-5 ">
							<TabContent activeTab={activeTab}>
								<div className="tab-content">
									<TabPane tabId="1">
										<div role="tabpanel" className="tab-pane fade show active" id="first">
											<img className="img-fluid" src={require("../../../../../images/product/1.jpg").default} alt="" />
										</div>
									</TabPane>	
									<TabPane tabId="2">
										<div role="tabpanel" className="tab-pane fade show" id="second">
											<img className="img-fluid" src={require("../../../../../images/product/2.jpg").default} alt="" />
										</div>
									</TabPane>	
									<TabPane tabId="3">
										<div role="tabpanel" className="tab-pane fade show" id="third">
											<img className="img-fluid" src={require("../../../../../images/product/3.jpg").default}	 alt="" />
										</div>
									</TabPane>	
									<TabPane tabId="4">
										<div role="tabpanel" className="tab-pane fade show" id="for">
											<img className="img-fluid" src={require("../../../../../images/product/4.jpg").default} alt="" />
										</div>
									</TabPane>	
								</div>
							</TabContent>	
							<div tabs>
								<div className="tab-slide-content new-arrival-product mb-4 mb-xl-0">
									<ul className="nav slide-item-list mt-3" role="tablist">
										<li
											className={classnames({ active: activeTab === '1' })}
											onClick={() => { toggle('1'); }}>
											<Link  role="tab" data-toggle="tab">
												<img className="img-fluid" src={require("../../../../../images/tab/1.jpg").default} alt="" width="50" />
											</Link>
										</li>
										 <li
											className={classnames({ active: activeTab === '2' })}
											onClick={() => { toggle('2'); }}>
											<Link role="tab" data-toggle="tab">
												<img className="img-fluid" src={require("../../../../../images/tab/2.jpg").default} alt	="" width="50" />
											</Link>
										</li>
										<li
											className={classnames({ active: activeTab === '3' })}
											onClick={() => { toggle('3'); }}>
											<Link role="tab" data-toggle="tab">
												<img className="img-fluid" src={require("../../../../../images/tab/3.jpg").default} alt="" width="50" />
											</Link>
										</li>
										<li
											className={classnames({ active: activeTab === '4' })}
											onClick={() => { toggle('4'); }}>
											<Link role="tab" data-toggle="tab">
												<img className="img-fluid" src={require("../../../../../images/tab/4.jpg").default} alt="" width="50" />
											</Link>
										</li>
									</ul>	
								</div>
							</div>	
						</div>	
                        <div className="col-xl-9 col-lg-6  col-md-6 col-xxl-7 col-sm-12">
                           <div className="product-detail-content">
                              <div className="new-arrival-content pr">
                                 <h4 >Solid Women's V-neck Dark T-Shirt</h4>
                                 <div className="star-rating  mb-2">
                                    {rating} 
									<span className="review-text"> (34 reviews) / </span>
                                    <Link className="product-review"  onClick={handleShow} to="/admin#">
                                       Write a review?
                                    </Link>
                                 </div>
                                 <p className="price">${price}</p>
                                 <p>
                                    Availability:
                                    <span className="item">
                                       {availability}
                                       <i className="fa fa-shopping-basket"></i>
                                    </span>
                                 </p>
                                 <p>
                                    Product code:
                                    <span className="item">{productCode}</span>
                                 </p>
                                 <p>
                                    Brand: <span className="item">{brand}</span>
                                 </p>
                                 <p>
                                    Product tags:&nbsp;&nbsp;
                                    {tags.map((tag, i) => (
                                       <span
                                          className="badge badge-success light mr-1"
                                          key={i}
                                       >
                                          {tag}
                                       </span>
                                    ))}
                                 </p>
                                 <p className="text-content">{des}</p>
                                 <div className="filtaring-area my-3">
                                    <div className="size-filter">
                                       <h4 className="m-b-15">Select size</h4>

                                       <div
                                          className="btn-group"
                                          data-toggle="buttons"
                                       >
                                          <label className="btn btn-outline-primary light btn-sm">
                                             <input
                                                type="radio"
                                                className="position-absolute invisible"
                                                name="options"
                                                id="option5"
                                             />
                                             XS
                                          </label>
                                          <label className="btn btn-outline-primary light btn-sm">
                                             <input
                                                type="radio"
                                                className="position-absolute invisible"
                                                name="options"
                                                id="option1"
                                             />
                                             SM
                                          </label>
                                          <label className="btn btn-outline-primary light btn-sm">
                                             <input
                                                type="radio"
                                                className="position-absolute invisible"
                                                name="options"
                                                id="option2"
                                             />
                                             MD
                                          </label>
                                          <label className="btn btn-outline-primary light btn-sm">
                                             <input
                                                type="radio"
                                                className="position-absolute invisible"
                                                name="options"
                                                id="option3"
                                             />
                                             LG
                                          </label>
                                          <label className="btn btn-outline-primary light btn-sm">
                                             <input
                                                type="radio"
                                                className="position-absolute invisible"
                                                name="options"
                                                id="option4"
                                             />
                                             XL
                                          </label>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-2 px-0">
                                    <input
                                       type="number"
                                       name="num"
                                       className="form-control input-btn input-number"
                                       defaultValue="1"
                                    />
                                 </div>
                                 <div className="shopping-cart mt-3">
                                    <Link
                                       className="btn btn-primary btn-lg"
                                       to="/admin#"
                                    >
                                       <i className="fa fa-shopping-basket mr-2"></i>
                                       Add to cart
                                    </Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
	   <Modal class="modal fade" show={show}  onHide={handleClose} id="reviewModal">
			<div  role="document">
				<div class="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Review</h5>
						<button type="button" className="close" onClick={handleClose} data-dismiss="modal"><span>&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<form>
							<div className="text-center mb-4">
								<img className="img-fluid rounded" width="78" src={avatar} alt="DexignLab" />
							</div>
							<div className="form-group ">
								<Ratingpage />
							</div>
							<div className="form-group">
								<textarea className="form-control" placeholder="Comment" rows="5"></textarea>
							</div>
								<button className="btn btn-success btn-block">RATE</button>
						</form>
					</div>
				</div>
			</div>
		</Modal>
		</Fragment>	
   );
};

export default ProductDetail;
