import React, {useState, useRef, Fragment, useEffect} from "react";
import {
   Row,
   Col,
   Card,
   Table,
   Badge,
   Dropdown,
   ProgressBar,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import PageTitle from "../../../../layouts/PageTitle";
import DynamicDataTable from "@langleyfoxall/react-dynamic-data-table";
import DynamicContextMenu from "@langleyfoxall/react-dynamic-data-table";
import {useDispatch, useSelector} from "react-redux";
import {delOneProfileEffect, getProfileEffect, usergetEffect} from "../../../../../../store/effects/UserEffects";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

const ProfilesList = (props) => {
   const dispatch = useDispatch()
   const [checked ,setChecked] = useState(new Set)

   const profileState = useSelector((state) => state.profile.profile)

   const svg1 = (
      <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
         <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
         </g>
      </svg>
   );

   useEffect(()=>{
      dispatch(getProfileEffect());
   },[])

   return (
      <div className="col-12 " >
      <PageTitle activeMenu="List" motherMenu="Roles" />
         <Fragment>
                  <Row className={"centerpage"}>
                     <Col lg={12}>
                        <Card>
                           <Card.Header>

                              {
                                 (checked.size > 0)? <button  className="btn btn-sm btn-danger mr-2" > <i className="fa fa-trash"></i> Delete all ({checked.size})</button>:<span></span>
                              }
                              {
                                 <Link to={"/admin/profiles/create"}  className="btn btn-sm btn-primary" > + Add</Link>
                              }
                           </Card.Header>
                           <Card.Body>
                              {
                                 profileState !== undefined && profileState.length > 0 &&  <DynamicDataTable
                                     rows={profileState}
                                     renderMasterCheckbox={false}
                                     renderCheckboxes

                                     fieldMap={{ id: '#'}}
                                     fieldsToExclude={["roles"]}
                                     columnWidths={{id: '50px'}}
                                     onCheckboxChange={(_, { id }) => {
                                        let checids = checked
                                        if (checids.has(id)) {
                                           checids.delete(id)
                                        } else {
                                           checids.add(id)
                                        }
                                        setChecked(checids)
                                        console.log(checked)



                                     }}
                                     dataItemManipulator={(field, value, row) => {
                                        switch(field) {
                                           case 'profil':
                                              return  value.label;

                                        }
                                        return value;
                                     }}

                                     buttons={row => (
                                         <Dropdown>
                                            <Dropdown.Toggle
                                                variant="primary"
                                                className="light sharp icon-false"
                                            >
                                               {svg1}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                               <Dropdown.Item> <Link to={"/admin/profiles/edit/"+row.id}> Details </Link></Dropdown.Item>
                                               <Dropdown.Item  className="text-danger" onClick={()=>{
                                                  swal({
                                                   title: "Are you sure?",
                                                   text: "Once deleted, you will not be able to recover it!",
                                                   icon: "warning",
                                                   buttons:true,
                                                   dangerMode: true,
                                                 })
                                                 .then((willDelete) => {
                                                   if (willDelete) {
                                                      delOneProfileEffect(row.id)
                                                      
                                                      swal("The item has been deleted!", {
                                                         icon: "success",
                                                      }).then(()=>{
                                                         dispatch(getProfileEffect());
                                                         window.location.reload();
                                                      });

                                                   } 
                                                 });
                                               }}> Delete </Dropdown.Item>
                                            </Dropdown.Menu>
                                         </Dropdown>
                                     )}


                                 />

                              } </Card.Body>
                        </Card>
                     </Col>
                     </Row>
               </Fragment>
   
            </div>
   );
};

export default ProfilesList;
