import { Action } from "redux";
import {
    Customer,
    FormRequest, FormStop,
    GetProfile, GetRoles,
    LoginError,
    LoginRequest,
    LoginSuccess,
    Profil, Role,
    Session,
    User,
    UserError,
    UserRequest,
    UserSuccess
} from "../../interfaces";
import {
    GET_USER_POPROFILE,
    GET_USER_ROLES,
    LOGIN_FAILURE,
    LOGIN_LOADING,
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    SIGNUP_FAILURE,
    SIGNUP_REQUEST,
    SIGNUP_SUCCESS,
    USER_FAILURE,
    USER_REQUEST,
    USER_SUCCESS
} from "../types/AccountTypes";
import {FORM_REQUEST, FORM_STOP} from "../types/RequestTypes";


export const GET_SESSION = 'GET_SESSION';
export const SIGN_IN = 'SIGN_IN';


export const get_session = () => {
    return {
      type: GET_SESSION
    }
}

export const loginRequest = (): LoginRequest => ({
    type: LOGIN_REQUEST
});
  
export const loginSuccess = (session: Session | undefined): LoginSuccess => ({
type: LOGIN_SUCCESS,
session
});




export const loginError = (message: string): LoginError => ({
type: LOGIN_FAILURE,
message
});


export const userRequest = (): UserRequest => ({
    type: USER_REQUEST
});


export const formRequest = (): FormRequest => ({
    type: FORM_REQUEST
});


export const formStop = (): FormStop => ({
    type: FORM_STOP
});
export const userSuccess = (user: User[]): UserSuccess => ({
    type: USER_SUCCESS,
    user
});



export const userError = (message: string): UserError => ({
    type: USER_FAILURE,
    message
});

export const getProfile = (profile: Profil[]): GetProfile => ({
    type: GET_USER_POPROFILE,
    profile
});

export const getRoles = (roles: Role[]): GetRoles => ({
    type: GET_USER_ROLES,
    roles
});