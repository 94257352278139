import React, {useState, useRef, Fragment, useEffect} from "react";
import {
   Row,
   Col,
   Card,
   Table,
   Badge,
   Dropdown,
   ProgressBar,
} from "react-bootstrap";
import { Link } from "react-router-dom";

import PageTitle from "../../../../layouts/PageTitle";
import DynamicDataTable from "@langleyfoxall/react-dynamic-data-table";
import DynamicContextMenu from "@langleyfoxall/react-dynamic-data-table";
import {useDispatch, useSelector} from "react-redux";
import { delOneAgenciesEffect, getAgenciesEffect } from "../../../../../../store/effects/FormEffects";
import swal from "sweetalert";
import i18n from "../../../../../../translations";

const AgenciesList = (props) => {
   const dispatch = useDispatch()

   const usersState = useSelector((state) => state.users)
   const [checked ,setChecked] = useState(new Set)
   const [agencies ,setAgencies] = useState([])

   const profileState = useSelector((state) => state.profile.profile)

   const svg1 = (
      <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
         <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
         </g>
      </svg>
   );


   useEffect(()=>{
      getAgenciesEffect().then((r)=>{
         
            const tmpagencies = [];

            r.map(v=>{
               const tmpgeted = [];
               for (const [key, value] of Object.entries(v)) {
                  console.log("key",key)
                  if (key==='agencyAddress'){
                     tmpgeted['City']= value.town;
                     tmpgeted['Street']= value.street;
                     tmpgeted['Location']= value.localisation;
                     tmpgeted['Postal code']= value.postalCode;
                     tmpgeted['Region']= value.region.name;
                  }
                  else{
                     tmpgeted[key]= value;
                  }
               }
               tmpagencies.push(tmpgeted)

            })
      
            setAgencies(tmpagencies)
      })

   }, [])

   return (
      <div className="col-12 " >
      <PageTitle activeMenu="List" motherMenu="Agencies" />
         <Fragment>
                  <Row className={"centerpage"}>
                     <Col lg={12}>
                        <Card>
                           <Card.Header>

                              {
                                 (checked.size > 0)? <button  className="btn btn-sm btn-danger mr-2" > <i className="fa fa-trash"></i> Delete all ({checked.size})</button>:<span></span>
                              }
                              {
                                 <Link to={"/admin/agencies/create"}  className="btn btn-sm btn-primary" > + Add</Link>
                              }
                           </Card.Header>
                           <Card.Body>
                              {
                                 agencies !== undefined && agencies.length > 0 &&  <DynamicDataTable
                                     rows={agencies}
                                     renderMasterCheckbox={false}
                                     renderCheckboxes

                                     fieldMap={{ id: '#'}}
                                     fieldsToExclude={[""]}
                                     columnWidths={{id: '50px'}}
                                     onCheckboxChange={(_, { id }) => {
                                        let checids = checked
                                        if (checids.has(id)) {
                                           checids.delete(id)
                                        } else {
                                           checids.add(id)
                                        }
                                        setChecked(checids)
                                        console.log(checked)



                                     }}
                                     dataItemManipulator={(field, value, row) => {
                                        switch(field) {
                                          case 'profil':
                                              return  value.label; break;
                                          case 'profil':
                                             return  value.label ;break;
                                          case 'profil':
                                             return  value.label ;break;

                                        }
                                        return value;
                                     }}

                                     buttons={row => (
                                         <Dropdown>
                                            <Dropdown.Toggle
                                                variant="primary"
                                                className="light sharp icon-false"
                                            >
                                               {svg1}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                               {
                                               //   <Dropdown.Item> <Link to={"/admin/profiles/edit/"+row.id}> Details </Link></Dropdown.Item>
                                               }
                                               <Dropdown.Item  className="text-danger" onClick={()=>{
                                                  swal({
                                                   title: "Are you sure?",
                                                   text: "Once deleted, you will not be able to recover it!",
                                                   icon: "warning",
                                                   buttons:true,
                                                   dangerMode: true,
                                                 })
                                                 .then((willDelete) => {
                                                   if (willDelete) {
                                                      delOneAgenciesEffect(row.id).then(()=>{
                                                            swal("The item has been deleted!", {
                                                            icon: "success",
                                                         }).then(()=>{
                                                            window.location.reload()
                                                         });
                                                      }).catch(e=>{
                                                         swal(
                                                            i18n.t('sorry')+'!',
                                                            i18n.t('error_deleting'),
                                                            "error"
                                                         )
                                                      })
                                                   } 
                                                 });
                                               }}> Delete </Dropdown.Item>
                                            </Dropdown.Menu>
                                         </Dropdown>
                                     )}


                                 />

                              } </Card.Body>
                        </Card>
                     </Col>
                     </Row>
               </Fragment>
   
            </div>
   );
};

export default AgenciesList;
