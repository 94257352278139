import axios from 'axios'
import SecureLS from 'secure-ls'
import { Convert } from '../../../interfaces';
//import TokenService from 'store/services/TokenServices';
//import { URLS } from './urls';


const ls = new SecureLS({ encodingType: 'aes' })

let session  = ls.get('session') 



if (session && session !==undefined )
  session = Convert.toSession(session);
else
    session = {
        id:0,
        username: '',
        email:    '',
        profil: undefined,
        type:'',
        token:'',
        expireIn: undefined
    }

console.log(session)

const IS_DEV = (window as any).__DEV__ !== undefined;

const language = ls.get('language')

const host = "http://api.eer.sunshine.mx/eer-bgfi";

//const host = "http://217.70.188.155:90/eer-bgfi";



const API = axios.create({
    //eslint-disable-next-line no-restricted-globals
    baseURL: host,
    headers: {
        'Authorization':session.type+' '+session.token, 
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language':(ls.get(language) && ls.get(language)!==null)?ls.get(language):'en-US'
    }
})


/*API.interceptors.response.use(
    (res: any) => {
        IS_DEV && console.log('RESPONSE', res);
        return res;
    },
    (error: { response: any; config: { __isRetryRequest: boolean; headers: { Authorization: null; }; url: any; method: string; data: { refresh_token: any; }; }; }) => {
        IS_DEV && console.log('RESPONSE-ERROR', error.response);
        IS_DEV && console.log('ERROR', error);

        if (error.response) {  
            
            const request = error.config;
            const response = error.response;

            const isBadToken = response.status === 401 //&& request && !request.url.includes(URLS.AUTH.LOGIN) && !request.url.includes(URLS.AUTH.REFRESH_TOKEN);

            if (isBadToken) {
                const orignalRequest = Object.assign({}, error.config);
                orignalRequest.__isRetryRequest = true;
                const refreshToken = ls.get('refresh_token')
                console.log('TOKEN EXPIRED, REFRESHING');
                console.log('REFRESH TOKEN:', refreshToken);
                error.config.__isRetryRequest = true;
                error.config.headers.Authorization = null; //'Bearer ' + refreshToken;
              
                error.config.method = 'POST';
                error.config.data = { refresh_token: refreshToken };
                console.log('refresh token request ==> ', error.config)
                return axios(error.config)
                    .then(res => {
                       
                        ls.set('access_token', res.data.access_token)
                        return axios({
                            ...orignalRequest,
                            headers: {
                                ...(orignalRequest.headers || {}),
                                Authorization: 'Bearer ' + res.data.access_token
                            }
                        })
                     
                    })
                    .catch(e => console.log('refresh error', e.response));
            }
    
           // if (response.status === 401 && request && request.url.includes(URLS.AUTH.REFRESH_TOKEN)) { 
                // store.dispatch(loggedOut());  // direct access to redux store.
                // SimpleToast.show(translate('COMMON.session-expired'));
            //}

            throw response;
        } else {
            throw error;
        }
    }
);*/


export default API
