import React, { useState , lazy} from "react";
import { Link } from "react-router-dom";
import {connect, Provider, useDispatch, useSelector} from 'react-redux';
import logo from '../../../assets/images/logo-bgfi.png';
import {loginEffect} from '../../../store/effects/AccountEffects';

function Login (props){
   const dispatch = useDispatch()
   const [loginFormData, setLoginFormData] = useState({});
   const handleBlur = (e) => {
      const newLoginFormData = { ...loginFormData };
      newLoginFormData[e.target.name] = e.target.value;
      setLoginFormData(newLoginFormData);
   };
   const submitHandler = (e) => {
      e.preventDefault();
      dispatch(loginEffect(loginFormData));
      
   };
   return (
      <div className="row justify-content-center h-100 align-items-center h-80">
         <div className="col-md-6">
            <div className="authincation-content">
               <div className="row no-gutters">
                  <div className="col-xl-12">
                     <div className="auth-form">
						<div className="text-center mb-2">
                  <div><Link to={"/"}><img src={logo} alt="" style={{padding:20,backgroundColor:'#fff', borderRadius:8}} /></Link></div>
						</div>
                        <h2 className="text-center mb-2 text-white"> EER Admin </h2>
                        <h4 className="text-center mb-2 text-white"> Sign in your account  </h4>
                        <form  action="" onSubmit={(e) => e.preventDefault(submitHandler)}  >
                           <div className="form-group">   
								<label className="mb-1 text-white">  <strong>Username</strong> </label>
								<input type="text"  name="username" className="form-control" placeholder="username"  onChange={handleBlur}   />
                           </div>
                           <div className="form-group">
                              <label className="mb-1 text-white "> <strong>Password</strong>  </label>
                              <input type="password" name="password" className="form-control" placeholder="Password"  onChange={handleBlur}  />
                           </div>
                           <div className="form-row d-flex justify-content-between mt-4 mb-2">
                              <div className="form-group">
                                 <div className="custom-control custom-checkbox ml-1 ">
                                    {/*
                                       <input  type="checkbox" className="custom-control-input"   id="basic_checkbox_1"  />
                                       <label  className="custom-control-label text-white" htmlFor="basic_checkbox_1"  >  Remember my preference </label>
                                          */
                                    }
								         </div>
                              </div>
                              <div className="form-group">
                                 <Link  className="text-white"  to="/admin/admin//admin/page-forgot-password"  > Forgot Password?  </Link>
                              </div>
                           </div>
                           <div className="text-center">
                              <button  type="submit"  className="btn btn-primary bg-white text-primary btn-block"  onClick={(e) => submitHandler(e)} disabled={props.session.loading.login_loading?'disabled' : null} > 
                             {
              
                                    (props.session.loading.login_loading) ?<>
                                    <span class="spinner-grow spinner-grow-sm"  role="status" aria-hidden="true"></span>
                                    <span class=""> Loading... </span></> : <span> Sign Me In </span> 
                              }
                              
                              </button>
                          
                           </div>
                        </form>
                        <div className="new-account mt-3">
                           {
                              
                           //<p className="text-white"> Don't have an account?{" "}   <Link className="text-primary" to="/admin/admin//page-register">  Sign up   </Link>  </p>
                           }
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

const mapStateToProps = (state) =>{
 
   return {
       session:state.account
       
   }}

const mapDispatchToProps = (state) =>{
  
   return {
      
   }}

export default connect(mapStateToProps,mapDispatchToProps)(Login);
