import { combineReducers, Reducer } from "redux";
import AccountReducer from "./reducers/system/AccountReducer";
import ProfileReducer from "./reducers/system/ProfileReducer";
import FormReducer from "./reducers/system/FormReducer";
import UserReducer from "./reducers/system/UserReducer";
import RolesReducer from "./reducers/system/RolesReducer";
import { ApplicationState } from "./types";


const rootReducer: Reducer<ApplicationState> = combineReducers<ApplicationState>({
    account: AccountReducer,
    users: UserReducer,
    profile: ProfileReducer,
    roles: RolesReducer,
    formRequest:FormReducer,
})

export default rootReducer;