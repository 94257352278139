export default {
    translation: {
        admin:{
            hello:"Bonjour"
        },
        PUT:"Peut modifier  ",
        POST:"Peut créer ",
        DELETE:"Peut supprimer ",
        GET:"Peut lire ",
        PATCH:"Peut partiellement modifier ",
        hello:"Bonjour",
        agencies:"Agences",
        "on.waiting.folder":"En attente de traitement",
        customers:"Clients",
        users:"Utilisateurs",
        done:"Terminé ",
        sorry:"Désolé ",
        success_add_user:"Utilisateur ajouté avec succès",
        success_editing:"Données sauvegardées avec succès",
        error_editing:"Une erreur s'est produite pendnat la sauvegarde, veuillez s'il réessayer plus tard",
        error_deleting:"Une erreur s'est produite pendnat la suppression ou l'élément est assigné à une autre ressource"
    }
}