import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// modal
import { Modal } from "react-bootstrap";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
	

/// Menu
import MetisMenu from "metismenujs";

class MM extends Component {
   componentDidMount() {
      this.$el = this.el;
      this.mm = new MetisMenu(this.$el);
   }
   componentWillUnmount() {
      //this.mm("dispose");
      this.mm.dispose();
   }
   render() {
      return (
         <div className="mm-wrapper">
            <ul className="metismenu" ref={(el) => (this.el = el)}>
               {this.props.children}
            </ul>
         </div>
      );
   }
}

class SideBar extends Component {
	
	 constructor(props) {
		 super(props);
		}
	
	/// model box
	state = {
		// initial state
		show: false,
	}

	handleClose = () => {
		this.setState({ show: false });
	};
	handleShow = () => {
		this.setState({ show: true });
	};
	
   /// Open menu
   componentDidMount() {
      // sidebar open/close
      var btn = document.querySelector(".nav-control");
      var aaa = document.querySelector("#main-wrapper");

      function toggleFunc() {
         return aaa.classList.toggle("menu-toggle");
      }

      btn.addEventListener("click", toggleFunc);
	  
	  
	  //sidebar icon Heart blast
	  var handleheartBlast = document.querySelector('.heart');

        function heartBlast() {
            return handleheartBlast.classList.toggle("heart-blast");
        }

        handleheartBlast.addEventListener('click', heartBlast);
   }
   render() {
      /// Path
      let path = window.location.pathname;
	  path = path.split("/");
      path = path[path.length - 1];
	  
      /// Active menu
      let dashBoard = [
            "",
            "projects",
            "contacts",
            "kanban",
            "calendar",
            "messages",
            //"analytics",
            //"companies",
            //"statistics",
			
         ],
         account = [
            "users",
            "profiles",
            "roles",
         ],
          products = [
              "products",
              "ranges",
          ],

          agencies = [
              "regions",
              "agencies",
          ],

         app = [
            "app-profile",
            "post-details",
            "app-calender",
            "email-compose",
            "email-inbox",
            "email-read",
            "ecom-product-grid",
            "ecom-product-list",
            "ecom-product-list",
            "ecom-product-order",
            "ecom-checkout",
            "ecom-invoice",
            "ecom-customers",
         ],
         charts = [
            "chart-morris",
            "chart-chartjs",
            "chart-chartist",
            "chart-sparkline",
            "chart-peity",
         ],
         bootstrap = [
            "ui-accordion",
            "ui-badge",
            "ui-alert",
            "ui-button",
            "ui-modal",
            "ui-button-group",
            "ui-list-group",
            "ui-media-object",
            "ui-card",
            "ui-carousel",
            "ui-dropdown",
            "ui-popover",
            "ui-progressbar",
            "ui-tab",
            "ui-typography",
            "ui-pagination",
            "ui-grid",
         ],
         plugins = [
            "uc-select2",
            "uc-nestable",
            "uc-sweetalert",
            "uc-toastr",
            "uc-jqvmap",
            "uc-noui-slider",
            "uc-lightgallery",
         ],
         widget = ["widget"],
         forms = [
            "form-element",
            "form-wizard",
            "form-editor-summernote",
            "form-pickers",
            "form-validation-jquery",
         ],
         table = ["table-bootstrap-basic", "table-datatable-basic"];

      return (
         <div className="dlabnav">
            <PerfectScrollbar className="dlabnav-scroll">
               <MM className="metismenu" id="menu">
                  <li
                      className={`${
                        dashBoard.includes(path) ? "mm-active" : ""
                     }`}
                  >
                     <Link
                        className="has-arrow ai-icon"
                        to="#"  
                     >
                        <i className="flaticon-381-networking"></i>
                        <span className="nav-text">Dashboard</span>
                     </Link>
                     <ul >
                        <li>
                           <Link
                              className={`${path === "" ? "mm-active" : ""}`}
                              to="/admin/"
                              onClick={() => this.props.onClick3()}
                           >
                              Dashboard
                           </Link>
                        </li>

                     </ul>
                  </li>
                  {
                     // account management links 
                  }

                  <li
                      className={`${
                        account.includes(path) ? "mm-active" : ""
                     }`}
                  >
                     <Link
                        className="has-arrow ai-icon"
                        to="#"  
                     >
                        <i className="flaticon-381-user"></i>
                        <span className="nav-text">Accounts</span>
                     </Link>
                     <ul>
                        <li>
                           <Link
                              className={`${path === "" ? "mm-active" : ""}`}
                              to="/admin/users"
                              onClick={() => this.props.onClick3()}
                           >
                              Users
                           </Link>
                        </li>
                         <li>
                             <Link
                                 className={`${path === "" ? "mm-active" : ""}`}
                                 to="/admin/profiles"
                                 onClick={() => this.props.onClick3()}
                             >
                                 Profiles
                             </Link>
                         </li>
                         {/*<li>
                         <Link
                             className={`${path === "" ? "mm-active" : ""}`}
                             to="/admin/roles"
                             onClick={() => this.props.onClick3()}
                         >
                             Roles
                         </Link>
                     </li>*/}
                     </ul>
                  </li>
                   {
                       // product
                   }
                   <li
                       className={`${
                           products.includes(path) ? "mm-active" : ""
                       }`}
                   >
                       <Link
                           className="has-arrow ai-icon"
                           to="#"
                       >
                           <i className="fa fa-product-hunt"></i>
                           <span className="nav-text">Products</span>
                       </Link>
                       <ul>

                           <li>
                               <Link
                                   className={`${path === "" ? "mm-active" : ""}`}
                                   to="/admin/products"
                                   onClick={() => this.props.onClick3()}
                               >
                                   Products
                               </Link>
                           </li>
                           <li>
                             <Link
                                 className={`${path === "" ? "mm-active" : ""}`}
                                 to="/admin/ranges"
                                 onClick={() => this.props.onClick3()}
                             >
                                 Ranges
                             </Link>
                         </li>
                       </ul>
                   </li>
                   {
                       // end of product
                   }
                   <li
                       className={`${
                           agencies.includes(path) ? "mm-active" : ""
                       }`}
                   >
                       <Link
                           className="has-arrow ai-icon"
                           to="#"
                       >
                           <i className="flaticon-381-location"></i>
                           <span className="nav-text">Agencies</span>
                       </Link>
                       <ul >
                           <li>
                               <Link
                                   className={`${path === "" ? "mm-active" : ""}`}
                                   to="/admin/agencies"
                                   onClick={() => this.props.onClick3()}
                               >
                                   Regions
                               </Link>
                           </li>

                           <li>
                               <Link
                                   className={`${path === "" ? "mm-active" : ""}`}
                                   to="/admin/agencies"
                                   onClick={() => this.props.onClick3()}
                               >
                                   Agencies
                               </Link>
                           </li>

                       </ul>
                   </li>
               {/*
                  <li className={`${app.includes(path) ? "mm-active" : ""}`}>
                     <Link
                        className="has-arrow ai-icon"
                        to="/admin#"
                     >
                        <i className="flaticon-381-television"></i>
                        <span className="nav-text">Apps</span>
                     </Link>
                     <ul >
                        <li>
                           <Link 
								className={`${ path === "app-profile" ? "mm-active" : ""}`}
								onClick={() => this.props.onClick()}
								to="/adminapp-profile">Profile
							</Link>
                        </li>
						<li>
                            <Link	
								className={`${ path === "post-details" ? "mm-active" : ""}`}
								onClick={() => this.props.onClick()}
								to="/admin/post-details">Post Details
							</Link>
                        </li>
                        <li >
                           <Link className="has-arrow"  to="/admin#"> Email</Link>
                           <ul >
								<li>
									<Link 
										className={`${ path === "email-compose"? "mm-active" : "" }`}
										onClick={() => this.props.onClick()}
										to="/admin/email-compose">Compose
									</Link>
								</li>
								<li>
									<Link 
										className={`${ path === "email-inbox"? "mm-active" : "" }`}
										onClick={() => this.props.onClick()}
										to="/admin/email-inbox">Inbox
									</Link>
								</li>
								<li>
									<Link
										className={`${ path === "email-read"? "mm-active" : "" }`}
										onClick={() => this.props.onClick()}
										to="/admine/mail-read">Read
									</Link>
								</li>
                           </ul>
                        </li>
                        <li>	
							<Link
								className={`${ path === "app-calender"? "mm-active" : "" }`}
								to="/admin/app-calender"
								onClick={() => this.props.onClick()}
								to="/admin/app-calender">Calendar
							</Link>
						</li>
                        <li><Link className="has-arrow" to="/admin#">Shop</Link>
                           <ul >
                              <li>
									<Link 
										className={`${ path === "ecom-product-grid" ? "mm-active" : ""}`}
										onClick={() => this.props.onClick()}
										to="/admine/com-product-grid">
										Product Grid
									</Link>
                              </li>
                              <li>
                                 <Link 
									className={`${ path === "ecom-product-list" ? "mm-active" : ""}`}
									onClick={() => this.props.onClick()}	
									to="/admin/ecom-product-list">
                                    Product List
                                 </Link>
                              </li>
                              <li>
                                 <Link 
									className={`${ path === "ecom-product-detail" ? "mm-active" : ""}`}
									onClick={() => this.props.onClick()}
									to="/admine/com-product-detail">
                                    Product Details
                                 </Link>
                              </li>
                              <li>
                                 <Link 
									className={`${ path === "ecom-product-order" ? "mm-active" : ""}`}
									onClick={() => this.props.onClick()}
									to="/admine/com-product-order">Order</Link>
                              </li>
                              <li>
                                 <Link className={`${ path === "ecom-checkout" ? "mm-active" : "" }`}
                                    to="/admin/ecom-checkout"
                                    onClick={() => this.props.onClick()}
                                 >
                                    Checkout
                                 </Link>
                              </li>
                              <li>
                                 <Link
                                    className={`${ path === "ecom-invoice" ? "mm-active" : "" }`}
                                    to="/admine/com-invoice"
                                    onClick={() => this.props.onClick()}
                                 >
                                    Invoice
                                 </Link>
                              </li>
                              <li>
                                 <Link
                                    className={`${
                                       path === "ecom-customers"
                                          ? "mm-active"
                                          : ""
                                    }`}
                                    to="/admin/ecom-customers"
                                    onClick={() => this.props.onClick()}
                                 >
                                    Customers
                                 </Link>
                              </li>
                           </ul>
                        </li>
                     </ul>
                  </li>
                  <li
                     className={`${
                        charts.includes(path.slice(1)) ? "mm-active" : ""
                     }`}
                  >
                     <Link
                        className="has-arrow ai-icon"
                        to="#"
                        
                     >
                        <i className="flaticon-381-controls-3"></i>
                        <span className="nav-text">Charts</span>
                     </Link>
                     <ul >
                        <li>
                           <Link
                              className={`${ path === "chart-rechart" ? "mm-active" : "" }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/chart-rechart"
                           >
                              RechartJs
                           </Link>
                        </li>
						<li>
                           <Link 
								className={`${ path === "chart-float" ? "mm-active" : "" }`}
								onClick={() => this.props.onClick()}
								to="/admin/chart-float">Chart Float
							</Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "chart-chartjs" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/chart-chartjs"
                           >
                              Chartjs
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "chart-chartist" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/chart-chartist"
                           >
                              Chartist
                           </Link>
                        </li>
                       <li>
                           <Link
                              className={`${
                                 path === "chart-sparkline" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/chart-sparkline"
                           >
                              Sparkline
                           </Link>
                        </li>
                         <li>
                           <Link
                              className={`${
                                 path === "chart-apexchart" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/chart-apexchart"
                           >
                              Apexchart
                           </Link>
                        </li>
                     </ul>
                  </li>

                  <li
                     className={`${
                        bootstrap.includes(path.slice(1)) ? "mm-active" : ""
                     }`}
                  >
                     <Link
                        className="has-arrow ai-icon"
                        to="#"
                        
                     >
                        <i className="flaticon-381-internet"></i>
                        <span className="nav-text">Bootstrap</span>
                     </Link>
                     <ul >
                       <li>
                           <Link
                              className={`${
                                 path === "ui-accordion" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/ui-accordion"
                           >
                              Accordion
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "ui-alert" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/ui-alert"
                           >
                              Alert
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "ui-badge" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/ui-badge"
                           >
                              Badge
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "ui-button" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/ui-button"
                           >
                              Button
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "ui-modal" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/ui-modal"
                           >
                              Modal
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "ui-button-group" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/ui-button-group"
                           >
                              Button Group
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "ui-list-group" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/ui-list-group"
                           >
                              List Group
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "ui-media-object" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/ui-media-object"
                           >
                              Media Object
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "ui-card" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/ui-card"
                           >
                              Cards
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "ui-carousel" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/ui-carousel"
                           >
                              Carousel
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "ui-dropdown" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/ui-dropdown"
                           >
                              Dropdown
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "ui-popover" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/ui-popover"
                           >
                              Popover
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "ui-progressbar" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/ui-progressbar"
                           >
                              Progressbar
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "ui-tab" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/ui-tab"
                           >
                              Tab
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "ui-typography" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/ui-typography"
                           >
                              Typography
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "ui-pagination" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/ui-pagination"
                           >
                              Pagination
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "ui-grid" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/ui-grid"
                           >
                              Grid
                           </Link>
                        </li>
                     </ul>
                  </li>
                  <li
                     className={`${
                        plugins.includes(path.slice(1)) ? "mm-active" : ""
                     }`}
                  >
                     <Link className="has-arrow ai-icon" to="/admin#" >
                        <i className="flaticon-381-heart"></i>
                        <span className="nav-text">Plugins</span>
                     </Link>
                     <ul >
                        <li>
                           <Link
                              className={`${
                                 path === "uc-select2" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/uc-select2"
                           >
                              Select 2
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "uc-nestable" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/uc-nestable"
                           >
                              Nestedable
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "uc-noui-slider" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/uc-noui-slider"
                           >
                              Noui Slider
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "uc-sweetalert" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/uc-sweetalert"
                           >
                              Sweet Alert
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "uc-toastr" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/uc-toastr"
                           >
                              Toastr
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${  path === "map-jqvmap" ? "mm-active" : "" }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/map-jqvmap"
                           >
                              Jqv Map
                           </Link>
                        </li>
						<li>
                           <Link
								className={`${  path === "uc-lightgallery" ? "mm-active" : "" }`}
								onClick={() => this.props.onClick()}
								to="/admin/uc-lightgallery">Light Gallery</Link>
                        </li>
                     </ul>
                  </li>
                  <li
                     className={`${
                        widget.includes(path.slice(1)) ? "mm-active" : ""
                     }`}
                  >
                     <Link
                        to="/admin/widget-basic"
                        className="ai-icon"
                        
                     >
                        <i className="flaticon-381-settings-2"></i>
                        <span className="nav-text">Widget</span>
                     </Link>
                  </li>
                  <li
                     className={`${
                        forms.includes(path.slice(1)) ? "mm-active" : ""
                     }`}
                  >
                     <Link
                        className="has-arrow ai-icon"
                        to="#"
                        
                     >
                        <i className="flaticon-381-notepad"></i>
                        <span className="nav-text forms">Forms</span>
                     </Link>
                     <ul >
                       <li>
                           <Link
                              className={`${
                                 path === "form-element" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/form-element"
                           >
                              Form Elements
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "form-wizard" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/form-wizard"
                           >
                              Wizard
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "form-editor-summernote"
                                    ? "mm-active"
                                    : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/form-editor-summernote"
                           >
                              Summernote
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "form-pickers" ? "mm-active" : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/form-pickers"
                           >
                              Pickers
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "form-validation-jquery"
                                    ? "mm-active"
                                    : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/form-validation-jquery"
                           >
                              Jquery Validate
                           </Link>
                        </li>
                     </ul>
                  </li>
                  <li
                     className={`${
                        table.includes(path.slice(1)) ? "mm-active" : ""
                     }`}
                  >
                     <Link
                        className="has-arrow ai-icon"
                        to="#"
                        
                     >
                        <i className="flaticon-381-network"></i>
                        <span className="nav-text">Table</span>
                     </Link>
                     <ul >
                        <li>
                           <Link
                              className={`${
                                 path === "table-bootstrap-basic"
                                    ? "mm-active"
                                    : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/table-bootstrap-basic"
                           >
                              Bootstrap
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${
                                 path === "table-datatable-basic"
                                    ? "mm-active"
                                    : ""
                              }`}
                              onClick={() => this.props.onClick()}
                              to="/admin/table-datatable-basic"
                           >
                              Datatable
                           </Link>
                        </li>
                     </ul>
                  </li>
                  <li >
                     <Link
                        className="has-arrow ai-icon"
                        to="#"
                        
                     >
                        <i className="flaticon-381-layer-1"></i>
                        <span className="nav-text">Pages</span>
                     </Link>
                     <ul >
                        <li>
                           <Link to="/admin/page-register">Register</Link>
                        </li>
                        <li>
                           <Link to="/admin/page-login">Login</Link>
                        </li>
                        <li>
                           <Link
                              className="has-arrow"
                              to="#"
                              
                           >
                              Error
                           </Link>
                           <ul >
                              <li>
                                 <Link to="/admin/page-error-400">Error 400</Link>
                              </li>
                              <li>
                                 <Link to="/admin/page-error-403">Error 403</Link>
                              </li>
                              <li>
                                 <Link to="/admin/page-error-404">Error 404</Link>
                              </li>
                              <li>
                                 <Link to="/admin/page-error-500">Error 500</Link>
                              </li>
                              <li>
                                 <Link to="/admin/page-error-503">Error 503</Link>
                              </li>
                           </ul>
                        </li>
                        <li>
                           <Link to="/admin/page-lock-screen">Lock Screen</Link>
                        </li>
                     </ul>
                  </li> */}
               </MM>
				{
              /* <div className="plus-box">
					<Link to={"#"}  onClick={this.handleShow} className="add-menu-sidebar d-block" data-toggle="modal" data-target="#addOrderModalside">+ New Project</Link>
				   </div>*/
            }
               <div className="copyright">
                  <p>
                     <strong>BGFI Bank</strong> © 2021 All Rights Reserved
                  </p>
                  <p>Made with <span className="heart"></span> by SunShine advisory</p>
               </div> 
            </PerfectScrollbar>
			
			{/* <!-- Add Order -->  model click by new project button*/}
			<Modal show={this.state.show} onHide={this.handleClose} className="modal fade" id="addOrderModalside">
				<div  role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Create Project</h5>
							<button type="button" onClick={this.handleClose} className="close" data-dismiss="modal"><span>&times;</span>	</button>
						</div>
						<div className="modal-body">
							<form>
								<div className="form-group">
									<label className="text-black font-w500">Project Name</label>
									<input type="text" className="form-control" />
								</div>
								<div className="form-group">
									<label className="text-black font-w500">Deadline</label>
									<input type="date" className="form-control" />
								</div>
								<div className="form-group">
									<label className="text-black font-w500">Client Name</label>
									<input type="text" className="form-control" />
								</div>
								<div className="form-group">
									<button type="button" className="btn btn-primary">CREATE</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</Modal>
         </div>
      );
   }
}

export default SideBar;
