import React, { useState, useRef, useEffect,Fragment} from "react";
import {connect, Provider, useDispatch, useSelector} from 'react-redux';
import {
   Row,
   Col,
   Card,
   Table,
   Button,
   Alert,
   Badge,
   Dropdown,
   ProgressBar,
} from "react-bootstrap";

import { useTranslation } from 'react-i18next';
import PageTitle from "../../../../layouts/PageTitle";
import {
   getOneUserEffect,
   getProfileEffect,
   userAddEffect,
   userUpdateEffect
} from '../../../../../../store/effects/UserEffects'
import {profileAddEffect, profileUpdateEffect} from "../../../../../../store/effects/FormEffects";
import { Link } from "react-router-dom";

const UserForm = (props) => {

   const { t, i18n } = useTranslation()
   const id = (props.match.params["id"]!== undefined && props.match.params["id"] !==null)?props.match.params["id"]:null;
   console.log(props.match.params);
   const dispatch = useDispatch()
   const userState = useSelector((state) => state.users)
   const profileState = useSelector((state) => state.profile.profile)
   const [userFormData, setUserFormData] = useState({
      email: "",
      firstName: "",
      lastName: "",
      matchingPassword: "",
      password: "",
      langKey: "",
      userName: "",
      profilId:1
   });
   const handleBlur = (e) => {
      const newUserFormData = { ...userFormData };
      if (e.target.name==='password')
         newUserFormData['matchingPassword'] = e.target.value;

      if (e.target.name==='profilId')
         newUserFormData['profilId'] = parseInt(e.target.value);

      newUserFormData[e.target.name] = e.target.value;
      setUserFormData(newUserFormData);
      
   };


   const svg1 = (
      <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
         <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
         </g>
      </svg>
   );



   const submitHandler = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity()===false) {
         event.preventDefault()
         event.stopPropagation()
      }else{
         event.preventDefault();
         (id==null)?dispatch(userAddEffect(userFormData,props)):dispatch(userUpdateEffect(userFormData,id,props));
      }

      form.classList.add('was-validated');

   };

   useEffect(()=>{
      dispatch(getProfileEffect());

      if (id!==null){
         getOneUserEffect(id).then((r)=>{
            const tmpgeted = { ...userFormData };
            for (const [key, value] of Object.entries(r)) {
               if (key=='profil')
                  tmpgeted['profilId']= value.id;
               tmpgeted[key]= value;

            }
            setUserFormData(tmpgeted)
         }).catch(err=>console.log(err));
      }

   }, [])

   return (
      <div className="col-12">
      <PageTitle activeMenu="Edit" motherMenu="Users" />
         <div className="card">
            <div className="card-header">
              <div style={{width:"90%"}}>
                 <h3>Edit user</h3>
              </div>
              <Link to="/admin/users" className="btn btn-primary btn-xs"><i className="fa fa-arrow-left"></i> Back</Link>
            </div>
            <div className="card-body pl-5">

               <Fragment>
                  
                  <Row>
                     <Col lg={6}>
                        <div className="basic-form">
                           <form class="row g-3 needs-validation" onSubmit={submitHandler} noValidate>
                              <div className="form-row">
                                 <div className="form-group col-md-12">
                                    <label>First Name</label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       placeholder="First Name"
                                       onChange={handleBlur}
                                       value={userFormData?.firstName}
                                       name='firstName'
                                    />
                                    <div class="invalid-feedback">
                                       Please enter a fist name
                                    </div>
                                 </div>
                                 <div className="form-group col-md-12">
                                    <label>Last Name <span style={{color:"red"}}>*</span></label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       placeholder="Last Name"
                                       required
                                       value={userFormData?.lastName}
                                       name='lastName'
                                       onChange={handleBlur}
                                    />
                                    <div class="invalid-feedback">
                                       Please enter a last name
                                    </div>
                                 </div>
                                 <div className="form-group col-md-12 has-validation">
                                    <label>Email <span style={{color:"red"}}>*</span></label>
                                    <input
                                       type="email"
                                       className="form-control"
                                       placeholder="user@example.com"
                                       required 
                                       name='email'
                                       readOnly={(id==null)?false:true}
                                       value={userFormData?.email}
                                       onChange={handleBlur}
                                    />
                                    <div class="invalid-feedback">
                                       Please enter a valid email address.
                                    </div>
                                 </div>
                                
                                 { id == null && <div className="form-group col-md-12 has-validation">
                                       <label>Username <span style={{color:"red"}}>*</span></label>
                                       <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Username"
                                          required
                                          value={userState.userName}
                                          name='userName'
                                          onChange={handleBlur}
                                       />
                                       <div class="invalid-feedback">
                                          Please enter a username.
                                       </div>
                                    </div>
                                 }
                                       { id == null &&  <div className="form-group col-md-12">
                                             <label>Password <span style={{color:"red"}}>*</span></label>
                                             <input
                                                minLength={6}
                                                type="password"
                                                className="form-control"
                                                placeholder="Password"
                                                required
                                                name='password'
                                                onChange={handleBlur}
                                             />
                                             <div class="invalid-feedback">
                                                Please set a password, 6 chars minimun.
                                             </div>
                                       </div>
                                    }
                                 
                                 <div className="form-group col-md-12">
                                    <label>Language</label>
                                    <select
                                       id="inputState"
                                       className="form-control"
                                       onChange={handleBlur}
                                       name='langKey'
                                    >
                                       <option value="" > -- select --</option>

                                       <option value="fr" selected={(userFormData.langKey==='fr')?true:false}>French</option>
                                       <option value="en" selected={(userFormData.langKey==='en')?true:false}>English</option>
                                    </select>
                                    <div class="invalid-feedback">
                                       Please pick a language
                                    </div>
                                 </div>

                                 <div className="form-group col-md-12">
                                    <label>Profile</label>
                                    <select
                                       id="inputState"
                                       className="form-control"
                                       required
                                       onChange={handleBlur}
                                       name="profilId"
                                    >
                                       <option value="">Choose...</option>
                                       {
                                          profileState != undefined && profileState.length > 0
                                          && profileState?.map((v,i)=>{
                                             return <option value={v.id} selected={(id!==null && userFormData.profilId==v.id)?true:false} >{v.label}</option>
                                          })
                                       }
                                    </select>
                                    <div class="invalid-feedback">
                                       Please select a user profile
                                    </div>
                                 </div>

                              </div>
                              
                              <div className="form-group col-md-12">

                              </div>{
                              ()=>{console.log('userstate',userState )}
                           }
                              <button type="submit" id="submit" className="btn btn-primary">
                                 
                                 {

                                     (userState.loading.user_loading) ?<>
                                     <span class="spinner-grow spinner-grow-sm"  role="status" aria-hidden="true"></span>
                                     <span class=""> Loading... </span></> : <span> {(id==null)?'Create':'Save'} </span> 
                                 }
                              </button>
                           </form>
                        </div>
                     </Col>
                  </Row>
               </Fragment>
   
            </div>
         </div>
      </div>
   );
};

export default UserForm;
