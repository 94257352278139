import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import SecureLS from 'secure-ls';

import { useState } from 'react';
import { ApplicationAction, ApplicationState } from '../types';
import { API } from '../../components/services/api';
import { loginError, loginRequest, loginSuccess } from '../actions/AccountAction';
import ts from "typescript/lib/tsserverlibrary";


const ls = new SecureLS({encodingType: 'aes'})

type Effect = ThunkAction<any, ApplicationState, any, ApplicationAction>;



export interface LoginInfo {
  "username": string,
  "password": string
}


export const loginEffect = (credentials: LoginInfo):Effect => async (
  dispatch
  ) => {
  dispatch(loginRequest())
  
  API.post("/users/login", credentials)
  .then(response => {
    const { data } = response
    ls.set('password', credentials.password)
    const session = data
    dispatch( 
     loginSuccess(session)
    )
    window.location.href='/admin/'
  }).catch((err) => {
    const {response} = err
    dispatch(loginError(response?.data))
    
    console.log(response?.data)
    
  })
}


export const logoutEffect = ():Effect => async (
    dispatch
) => {
  loginSuccess(undefined)
  ls.clear()
}

