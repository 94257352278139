import { useState, useEffect,Fragment} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {
   Row,
   Col,
 
} from "react-bootstrap";

import { useTranslation } from 'react-i18next';
import PageTitle from "../../../../layouts/PageTitle";
import {profileAddEffect, profileUpdateEffect} from "../../../../../../store/effects/FormEffects";
import {getOneProfileEffect, getRolesEffect} from "../../../../../../store/effects/UserEffects";
import Select  from 'react-select';
import AsyncSelect from 'react-select/async';
import makeAnimated from 'react-select/animated';
import { Link } from "react-router-dom";

const animatedComponents = makeAnimated();

const ProfilesForm = (props) => {
   const {t} = useTranslation()
   const id = (props.match.params["id"]!== undefined && props.match.params["id"] !==null)?props.match.params["id"]:null;
   const dispatch = useDispatch()
   const userState = useSelector((state) => state.users)
   const rolesState = useSelector((state) => state.roles.roles)
   const formrequestState = useSelector((state) => state.formRequest)

   const [profileFormData, setProfileFormData] = useState({
      label: "",
      description: "",
      roles:[]
   });
   const [rolesFormData, setRolesFormData] = useState([])

   const [oldsRoles, setOldsRoles] = useState([])

   const handleBlur = (e) => {
      const newProfileFormData = { ...profileFormData };
      if (e.target.name==='roles[]'){
         const selectedRoles = Array.from(e.target.selectedOptions).map(({ value }) => JSON.parse(value))
         setRolesFormData(selectedRoles);
      }

      newProfileFormData[e.target.name] = e.target.value;
      setProfileFormData(newProfileFormData);
      
   };


   const svg1 = (
      <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
         <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
         </g>
      </svg>
   );

   const submitHandler = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity()===false) {
         event.preventDefault()
         event.stopPropagation()
      }else{
         event.preventDefault();
         (id==null)?dispatch(profileAddEffect(profileFormData,rolesFormData, props)):dispatch(profileUpdateEffect(profileFormData,rolesFormData, id, props))
      }

      form.classList.add('was-validated');

   };

   const setTypeData = (data) =>{
      const selectedRoles = Array.from(data).map(d => JSON.parse(d.value))
      setRolesFormData(selectedRoles);
  }

  


   useEffect(()=>{
      dispatch(getRolesEffect())
      if (id!==null){
         getOneProfileEffect(id).then((r)=>{
            
            const tmpgeted = { ...profileFormData };

            for (const [key, value] of Object.entries(r)) {
               if(key !==  'roles'){
                  tmpgeted[key]= value;
               }else{
                  tmpgeted[key]=r[key]?.map((v)=>({value:v.id, label: t(v.method) +v.resourcePath.split('/')[1]}))
                  
                  setTypeData(r[key]?.map((v)=>({value:v.id, label: t(v.method) +v.resourcePath.split('/')[1]})))
               }
            }

            setProfileFormData(tmpgeted)
         }).catch(err=>console.log(err));
      }
   }, [])

   return (
      <div className="col-12">
      <PageTitle activeMenu="Edit" motherMenu="Profile" />
         <div className="card">
            <div className="card-header">
              <div style={{width:"90%"}}>
                 <h3>Profile Editing</h3>
              </div>
              <Link to="/admin/profiles" className="btn btn-primary btn-xs"><i className="fa fa-arrow-left"></i> Back</Link>
            </div>
            <div className="card-body pl-5">

               <Fragment>
                  
                  <Row>
                     <Col lg={10}>
                        <div className="basic-form">
                           <form class="g-3 needs-validation" onSubmit={submitHandler} noValidate>
                              <div className="form-row">
                                 <div className="form-group col-md-6">
                                    <label>Label <span style={{color:"red"}}>*</span></label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       placeholder="profile label"
                                       onChange={handleBlur}
                                       value= {profileFormData?.label}
                                       required
                                       name='label'
                                    />
                                    <div class="invalid-feedback">
                                       Please enter a label
                                    </div>
                                 </div>
                                 <div className="form-group col-md-6">
                                    <label>Description</label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       placeholder="label description"
                                       value = {profileFormData?.description}
                                       name='description'
                                       onChange={handleBlur}
                                    />
                                    <div class="invalid-feedback">
                                       Please enter a description for label
                                    </div>
                                 </div>

                                 <div className="form-group col-md-12">
                                    <label>Actions to assign</label>
                                  {
                                     id !==null && profileFormData['roles'].length>0 &&  <Select
                                      onChange = {setTypeData}
                                      closeMenuOnSelect={false}
                                      components={animatedComponents}
                                      defaultValue = {('roles' in profileFormData) ?profileFormData['roles']:[]}
                                     
                                      isMulti
                                      options={rolesState?.map((v)=>({value:v.id, label: t(v.method) +v.resourcePath.split('/')[1]}))}
                                  /> 
                                  } 
                                  {
                                     id === null && <Select
                                      onChange = {setTypeData}
                                      closeMenuOnSelect={false}
                                      components={animatedComponents}
                                     
                                      isMulti
                                      options={rolesState?.map((v)=>({value:v.id, label: t(v.method) +v.resourcePath.split('/')[1]}))}
                                  /> 
                                  }
                                 
                              
                          
                                    {/*<select className="form-select form-control" name="roles[]" onChange={handleBlur} multiple aria-label="multiple roles">
                                       {
                                          rolesState != undefined && rolesState.length > 0
                                          && rolesState?.map((v,i)=>{
                                             return <option value={JSON.stringify(v)}>{t(v.method) +v.resourcePath.split('/')[1]}</option>
                                          })
                                       }
                                    </select>*/}

                                 </div>
                              </div>
                              
                              <div className="form-group col-md-12">

                              </div>{
                              ()=>{console.log('userstate',userState )}
                           }
                              <button type="submit" id={"submit"} disabled={(formrequestState.form_loading) ?true:false} className="btn btn-primary">
                                 
                                 {

                                     (formrequestState.form_loading) ?<>
                                     <span class="spinner-grow spinner-grow-sm"  role="status" aria-hidden="true"></span>
                                     <span class=""> Loading... </span></> : <span> {(id===null)?'Create':'Save'} </span> 
                                 }
                              </button>
                           </form>  
                        </div>
                     </Col>
                  </Row>
               </Fragment>
   
            </div>
         </div>
      </div>
   );
};

export default ProfilesForm;
