import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import { Modal} from "react-bootstrap";

import user1   from '../../../../images/users/1.jpg'; 
import user2   from '../../../../images/users/2.jpg'; 
import user3   from '../../../../images/users/3.jpg'; 
import user4   from '../../../../images/users/4.jpg'; 
import user5   from '../../../../images/users/5.jpg'; 
import user6   from '../../../../images/users/6.jpg'; 
import user7   from '../../../../images/users/7.jpg'; 
import user8   from '../../../../images/users/8.jpg'; 
import user9   from '../../../../images/users/9.jpg'; 
import user10 from '../../../../images/users/10.jpg'; 
import user11 from '../../../../images/users/11.jpg'; 
import user12 from '../../../../images/users/12.jpg'; 

import user14 from '../../../../images/users/14.png'; 
import user15 from '../../../../images/users/15.png'; 
import user16 from '../../../../images/users/16.png'; 
import user17 from '../../../../images/users/17.png'; 
import user18 from '../../../../images/users/18.png'; 
import user19 from '../../../../images/users/19.png'; 
import user20 from '../../../../images/users/20.png'; 



class Kanban extends Component{
	
	state = {
		// initial state
		show: false,
	}

	handleClose = () => {
		this.setState({ show: false });
	};
	handleShow = () => {
		this.setState({ show: true });
	};
	
	render(){
		return(
			<Fragment>
				<div className="row">
					<div className="col-xl-12">
						<div className="card">
							<div className="card-body">
								<div className="d-md-flex d-block mb-md-4 mb-3 align-items-end">
									<div className="mr-auto pr-3 mb-md-0 mb-3">
										<h2 className="title-num text-black font-w600">Base Vora’s Project v2.4</h2>
										<span className="fs-14">Created by Lidya Chan on June 31, 2020</span>
									</div>
									<ul className="users-lg">
										<li><img src={user14} alt="" /></li>
										<li><img src={user15} alt="" /></li>
										<li><img src={user16} alt="" /></li>
										<li><img src={user17} alt="" /></li>
										<li><img src={user18} alt="" /></li>
										<li><img src={user19} alt="" /></li>
										<li><img src={user20} alt="" /></li>
									</ul>
								</div>
								<div className="row">
									<p className="fs-14 mr-auto col-lg-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
									<div className="col-lg-6 text-lg-right text-left">
										<Link to={"#"} onClick={this.handleShow} data-toggle="modal" data-target="#addOrderModal" className="btn btn-primary rounded mr-3 mb-sm-0 mb-2"><i className="fa fa-user mr-3 scale5" aria-hidden="true"></i>New Contact</Link>
										<Link to={"#"} className="btn btn-light rounded mr-3 mb-sm-0 mb-2"><i className="fa fa-pencil-square mr-3 scale5" aria-hidden="true"></i>Edit</Link>
										<Link to={"#"} className="btn btn-light rounded mb-sm-0 mb-2"><i className="fa fa-lock mr-3 scale5" aria-hidden="true"></i>Private</Link>
									</div>
									{/* <!-- Add Order modal --> */}
									<Modal className="modal fade" show={this.state.show} onHide={this.handleClose} id="addOrderModal">
										<div  role="document">
											<div className="modal-content">
												<div className="modal-header">
													<h5 className="modal-title">Add Contact</h5>
													<button type="button" className="close" onClick={this.handleClose} data-dismiss="modal"><span>&times;</span>
													</button>
												</div>
												<div className="modal-body">
													<form>
														<div className="form-group">
															<label className="text-black font-w500">First Name</label>
															<input type="text" className="form-control" />
														</div>
														<div className="form-group">
															<label className="text-black font-w500">Last Name</label>
															<input type="text" className="form-control" />
														</div>
														<div className="form-group">
															<label className="text-black font-w500">Address</label>
															<input type="text" className="form-control" />
														</div>
														<div className="form-group">
															<button type="button" className="btn btn-primary">SAVE</button>
														</div>
													</form>
												</div>
											</div>
										</div>
									</Modal>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row kanban-bx">
					<div className="col">
						<div className="card">
							<span className="line bg-secondary"></span>
							<div className="card-header shadow-sm">
								<div>
									<h4 className="fs-20 mb-0 font-w600 text-black">To-Do List (24)</h4>
									<span className="fs-14">Lorem ipsum dolor sit amet</span>
								</div>
								<Link to={"/contacts"} className="plus-icon"><i className="fa fa-plus" aria-hidden="true"></i></Link>
							</div>
							<div className="card-body loadmore-content dlab-scroll  pb-0" id="RecentActivitiesContent">
								<div className="border-bottom pb-4 mb-4">
									<Link to={"#"} className="btn btn-sm btn-success rounded-xl mb-2">Graphic Deisgner</Link>
									<p className="font-w600"><Link to={"/post-details"} className="text-black">Visual Graphic for Presentation to Client</Link></p>
									<div className="row justify-content-between">
										<ul className="users col-6">
											<li><img src={user1} alt="" /></li>
											<li><img src={user2} alt="" /></li>
											<li><img src={user3} alt="" /></li>
											<li><img src={user4} alt="" /></li>
										</ul>
										<div className="col-6 pl-0">
											<h6 className="fs-14">Progress
												<span className="pull-right font-w600">24%</span>
											</h6>
											<div className="progress" style={{height:"7px"}}>
												<div className="progress-bar bg-primary progress-animated" style={{width: "24%" ,height:"7px"}} role="progressbar">
													<span className="sr-only">24% Complete</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="border-bottom pb-4 mb-4">
									<Link to={"#"} className="btn btn-sm btn-secondary rounded-xl mb-2">Digital Marketing</Link>
									<p className="font-w600"><Link to={"/post-details"} className="text-black">Build Database Design for Fasto Admin v2</Link></p>
									<div className="row justify-content-between">
										<ul className="users col-6">
											<li><img src={user5} alt="" /></li>
											<li><img src={user6} alt="" /></li>
											<li><img src={user7} alt="" /></li>
											<li><img src={user8} alt="" /></li>
										</ul>
										<div className="col-6 pl-0">
											<h6 className="fs-14">Progress
												<span className="pull-right font-w600">79%</span>
											</h6>
											<div className="progress" style={{height:"7px"}}>
												<div className="progress-bar bg-primary progress-animated" style={{width:"79%", height:"7px"}} role="progressbar">
													<span className="sr-only">79% Complete</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="border-bottom pb-4 mb-4">
									<Link to={"#"} className="btn btn-sm btn-warning rounded-xl mb-2">Programmer</Link>
									<p className="font-w600"><Link to={"/post-details"} className="text-black">Make Promotional Ads for Instagram Fasto’s</Link></p>
									<div className="row justify-content-between">
										<ul className="users col-6">
											<li><img src={user9}  alt="" /></li>
											<li><img src={user10} alt="" /></li>
											<li><img src={user11} alt="" /></li>
											<li><img src={user12} alt="" /></li>
										</ul>
										<div className="col-6 pl-0">
											<h6 className="fs-14">Progress
												<span className="pull-right font-w600">36%</span>
											</h6>
											<div className="progress" style={{height:"7px"}}>
												<div className="progress-bar bg-primary progress-animated" style={{width: "36%", height:"7px"}} role="progressbar">
													<span className="sr-only">36% Complete</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="pb-4">
									<Link to={"#"} className="btn btn-sm btn-secondary rounded-xl mb-2">Digital Marketing</Link>
									<p className="font-w600"><Link to={"/post-details"} className="text-black">Build Database Design for Fasto Admin v2</Link></p>
									<div className="row justify-content-between">
										<ul className="users col-6">
											<li><img src={user5} alt="" /></li>
											<li><img src={user6} alt="" /></li>
											<li><img src={user7} alt="" /></li>
											<li><img src={user8} alt="" /></li>
										</ul>
										<div className="col-6 pl-0">
											<h6 className="fs-14">Progress
												<span className="pull-right font-w600">79%</span>
											</h6>
											<div className="progress" style={{height:"7px"}}>
												<div className="progress-bar bg-primary progress-animated" style={{width:"79%", height:"7px"}} role="progressbar">
													<span className="sr-only">79% Complete</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-footer">
								<Link className="btn d-block btn-primary light dlab-load-more" to={"#"}  id="RecentActivities"><strong>26</strong> Tasks More</Link>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card">
							<span className="line bg-warning"></span>
							<div className="card-header shadow-sm">
								<div>
									<h4 className="fs-20 mb-0 font-w600 text-black">OnProgress (2)</h4>
									<span className="fs-14">Lorem ipsum dolor sit amet</span>
								</div>
								<Link to={"/contacts"} className="plus-icon"><i className="fa fa-plus" aria-hidden="true"></i></Link>
							</div>
							<div className="card-body pb-0">
								<div className="border-bottom pb-4 mb-4">
									<Link to={"#"} className="btn btn-sm btn-info rounded-xl mb-2">UX Writer</Link>
									<p className="font-w600"><Link to={"post-details"} className="text-black">Caption description for onboarding screens</Link></p>
									<div className="row justify-content-between">
										<ul className="users col-6">
											<li><img src={user1} alt="" /></li>
											<li><img src={user2} alt="" /></li>
											<li><img src={user3} alt="" /></li>
											<li><img src={user4} alt="" /></li>
										</ul>
										<div className="col-6 pl-0">
											<h6 className="fs-14">Progress
												<span className="pull-right font-w600">24%</span>
											</h6>
											<div className="progress" style={{height:"7px"}}>
												<div className="progress-bar bg-primary progress-animated" style={{width: "24%", height:"7px"}} role="progressbar">
													<span className="sr-only">24% Complete</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="pb-4">
									<Link to={"#"} className="btn btn-sm btn-secondary rounded-xl mb-2">Digital Marketing</Link>
									<p className="font-w600"><Link to={"/post-details"} className="text-black">Branding New Visual Vora</Link></p>
									<div className="row justify-content-between">
										<ul className="users col-6">
											<li><img src={user5} alt="" /></li>
											<li><img src={user6} alt="" /></li>
										</ul>
										<div className="col-6 pl-0">
											<h6 className="fs-14">Progress
												<span className="pull-right font-w600">79%</span>
											</h6>
											<div className="progress" style={{height:"7px"}}>
												<div className="progress-bar bg-primary progress-animated" style={{width: "79%", height:"7px"}} role="progressbar">
													<span className="sr-only">79% Complete</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card">
							<span className="line bg-success"></span>
							<div className="card-header shadow-sm">
								<div>
									<h4 className="fs-20 mb-0 font-w600 text-black">Done (3)</h4>
									<span className="fs-14">Lorem ipsum dolor sit amet</span>
								</div>
								<Link to={"/contacts"} className="plus-icon"><i className="fa fa-plus" aria-hidden="true"></i></Link>
							</div>
							<div className="card-body pb-0">
								<div className="border-bottom pb-4 mb-4">
									<Link to={"#"} className="btn btn-sm btn-warning rounded-xl mb-2">Sales Marketing</Link>
									<p className="font-w600"><Link to={"/post-details"} className="text-black">Get 1,000 New Leads for Next Month Target</Link></p>
									<div className="row justify-content-between">
										<ul className="users col-6">
											<li><img src={user9} alt="" /></li>
										</ul>
										<div className="col-6 pl-0">
											<h6 className="fs-14">Progress
												<span className="pull-right font-w600">36%</span>
											</h6>
											<div className="progress" style={{height:"7px"}}>
												<div className="progress-bar bg-primary progress-animated" style={{width: "36%", height:"7px"}} role="progressbar">
													<span className="sr-only">36% Complete</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="border-bottom pb-4 mb-4">
									<Link to={"#"} className="btn btn-sm btn-success rounded-xl mb-2">Graphic Deisgner</Link>
									<p className="font-w600"><Link to={"/post-details"} className="text-black">Visual Graphic for Presentation to Client</Link></p>
									<div className="row justify-content-between">
										<ul className="users col-6">
											<li><img src={user1} alt="" /></li>
											<li><img src={user2} alt="" /></li>
											<li><img src={user3} alt="" /></li>
											<li><img src={user4} alt="" /></li>
										</ul>
										<div className="col-6 pl-0">
											<h6 className="fs-14">Progress
												<span className="pull-right font-w600">24%</span>
											</h6>
											<div className="progress" style={{height:"7px"}}>
												<div className="progress-bar bg-primary progress-animated" style={{width: "24%", height:"7px"}} role="progressbar">
													<span className="sr-only">24% Complete</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="pb-4">
									<Link to={"#"} className="btn btn-sm btn-warning rounded-xl mb-2">Programmer</Link>
									<p className="font-w600"><Link to={"/post-details"} className="text-black">Make Promotional Ads for Instagram Fasto’s</Link></p>
									<div className="row justify-content-between">
										<ul className="users col-6">
											<li><img src={user5} alt="" /></li>
											<li><img src={user6} alt="" /></li>
										</ul>
										<div className="col-6 pl-0">
											<h6 className="fs-14">Progress
												<span className="pull-right font-w600">79%</span>
											</h6>
											<div className="progress" style={{height:"7px"}}>
												<div className="progress-bar bg-primary progress-animated" style={{width:"79%", height:"7px"}} role="progressbar">
													<span className="sr-only">79% Complete</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card">
							<span className="line bg-danger"></span>
							<div className="card-header shadow-sm">
								<div>
									<h4 className="fs-20 mb-0 font-w600 text-black">Revised (0)</h4>
									<span className="fs-14">Lorem ipsum dolor sit amet</span>
								</div>
								<Link to={"/contacts"} className="plus-icon"><i className="fa fa-plus" aria-hidden="true"></i></Link>
							</div>
							<div className="card-body pb-0">
								<div className="pb-4">
									<Link to={"#"} className="btn btn-sm btn-secondary rounded-xl mb-2">Digital Marketing</Link>
									<p className="font-w600"><Link to={"/post-details"} className="text-black">Build Database Design for Fasto Admin v2</Link></p>
									<div className="row justify-content-between">
										<ul className="users col-6">
											<li><img src={user5} alt="" /></li>
											<li><img src={user6} alt="" /></li>
											<li><img src={user7} alt="" /></li>
											<li><img src={user8} alt="" /></li>
										</ul>
										<div className="col-6 pl-0">
											<h6 className="fs-14">Progress
												<span className="pull-right font-w600">79%</span>
											</h6>
											<div className="progress" style={{height:"7px"}}>
												<div className="progress-bar bg-primary progress-animated" style={{width:"79%", height:"7px"}} role="progressbar">
													<span className="sr-only">79% Complete</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card">
							<span className="line bg-dark"></span>
							<div className="card-header shadow-sm">
								<div>
									<h4 className="fs-20 mb-0 font-w600 text-black">To-Do List (24)</h4>
									<span className="fs-14">Lorem ipsum dolor sit amet</span>
								</div>
								<Link to={"/contacts"} className="plus-icon"><i className="fa fa-plus" aria-hidden="true"></i></Link>
							</div>
							<div className="card-body pb-0">
								<div className="border-bottom pb-4 mb-4">
									<Link to={"#"} className="btn btn-sm btn-success rounded-xl mb-2">Graphic Deisgner</Link>
									<p className="font-w600"><Link to={"/post-details"} className="text-black">Visual Graphic for Presentation to Client</Link></p>
									<div className="row justify-content-between">
										<ul className="users col-6">
											<li><img src={user1} alt="" /></li>
											<li><img src={user2} alt="" /></li>
											<li><img src={user3} alt="" /></li>
											<li><img src={user4} alt="" /></li>
										</ul>
										<div className="col-6 pl-0">
											<h6 className="fs-14">Progress
												<span className="pull-right font-w600">24%</span>
											</h6>
											<div className="progress" style={{height:"7px"}}>
												<div className="progress-bar bg-primary progress-animated" style={{width:"24%", height:"7px"}} role="progressbar">
													<span className="sr-only">24% Complete</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="border-bottom pb-4 mb-4">
									<Link to={"#"} className="btn btn-sm btn-secondary rounded-xl mb-2">Digital Marketing</Link>
									<p className="font-w600"><Link to={"/post-details"} className="text-black">Build Database Design for Fasto Admin v2</Link></p>
									<div className="row justify-content-between">
										<ul className="users col-6">
											<li><img src={user5} alt="" /></li>
											<li><img src={user6} alt="" /></li>
											<li><img src={user7} alt="" /></li>
											<li><img src={user8} alt="" /></li>
										</ul>
										<div className="col-6 pl-0">
											<h6 className="fs-14">Progress
												<span className="pull-right font-w600">79%</span>
											</h6>
											<div className="progress" style={{height:"7px"}}>
												<div className="progress-bar bg-primary progress-animated" style={{width:"79%", height:"7px"}} role="progressbar">
													<span className="sr-only">79% Complete</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="border-bottom pb-4 mb-4">
									<Link to={"#"} className="btn btn-sm btn-warning rounded-xl mb-2">Programmer</Link>
									<p className="font-w600"><Link to={"/post-details"} className="text-black">Make Promotional Ads for Instagram Fasto’s</Link></p>
									<div className="row justify-content-between">
										<ul className="users col-6">
											<li><img src={user9}  alt="" /></li>
											<li><img src={user10} alt="" /></li>
											<li><img src={user11} alt="" /></li>
											<li><img src={user12} alt="" /></li>
										</ul>
										<div className="col-6 pl-0">
											<h6 className="fs-14">Progress
												<span className="pull-right font-w600">36%</span>
											</h6>
											<div className="progress" style={{height:"7px"}}>
												<div className="progress-bar bg-primary progress-animated" style={{width:"36%", height:"7px"}} role="progressbar">
													<span className="sr-only">36% Complete</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="pb-4">
									<Link to={"#"} className="btn btn-sm btn-secondary rounded-xl mb-2">Digital Marketing</Link>
									<p className="font-w600"><Link to={"/post-details"} className="text-black">Build Database Design for Fasto Admin v2</Link></p>
									<div className="row justify-content-between">
										<ul className="users col-6">
											<li><img src={user5} alt="" /></li>
											<li><img src={user6} alt="" /></li>
											<li><img src={user7} alt="" /></li>
											<li><img src={user8} alt="" /></li>
										</ul>
										<div className="col-6 pl-0">
											<h6 className="fs-14">Progress
												<span className="pull-right font-w600">79%</span>
											</h6>
											<div className="progress" style={{height:"7px"}}>
												<div className="progress-bar bg-primary progress-animated" style={{width:"79%",	 height:"7px"}} role="progressbar">
													<span className="sr-only">79% Complete</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</Fragment>
		)
	}	
}
export default Kanban