import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { enableES5 } from 'immer';
import './translations'
import { createBrowserHistory } from 'history';
import persistStore from 'redux-phoenix';
//import { AnyAction, Store } from 'redux';
//import { ApplicationState } from 'store/types';
import SecureLS from 'secure-ls';
import { Router } from 'react-router';
import { ApplicationState } from './store/types';
import { AnyAction, Store } from 'redux';

const ls = new SecureLS({ encodingType: 'aes' });
const history = createBrowserHistory();
enableES5();
// @ts-ignore
ls.setItem = ls.set;
// @ts-ignore
ls.getItem = ls.get;

/*
whitelist: ['users'],
blacklist: ['users.loading.users'],
*/

persistStore(store, {
  storage: ls,
  serialize: (data: any) => data,
  deserialize: (data: any) => data,
  throttle: 5 * 1000 //wait 5 seconds before save
}).then((store: Store<ApplicationState, AnyAction>) => {

ReactDOM.render(
  <React.StrictMode>
    <Router history={history} >
      <App store={store} />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
