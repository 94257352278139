import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import SecureLS from 'secure-ls';
import { useHistory } from "react-router-dom";
import { useState } from 'react';
import { ApplicationAction, ApplicationState } from '../types';
import { API } from '../../components/services/api';
import swal from "sweetalert";
import {getProfile, getRoles, userError, userRequest, userSuccess} from '../actions/AccountAction';
import i18n from "../../translations";
import { User } from '../../interfaces';

const ls = new SecureLS({encodingType: 'aes'})

type Effect = ThunkAction<any, ApplicationState, any, ApplicationAction>;



export interface Userformdata {
  "userName"?: string,
  "password"?: string,
  "matchingPassword"?: string,
  "firstName": string,
  "lastName": string,
  "email": string,
  "langKey": string,
  "profilId": number
}

export interface Profilformdata {
  "label": string,
  "description": string
}


export const userAddEffect = (userformdata: Userformdata, props:any):Effect => async (
  dispatch
  ) => {
  dispatch(userRequest())
  
  API.post("/users/registration", userformdata)
  .then(response => {
    const { data } = response.data
    const user = data

      API.get('/users').then(response=>{
          dispatch(
              userSuccess(response.data)
          )
      })

      swal(
          i18n.t('done')+'!',
          i18n.t('success_add_user'),
          "success"
      ).then((value) => {
        props.history.push("/admin/users");
      })
  }).catch((err) => {
      console.log('error',err)
    const {response} = err
    dispatch(userError(response?.data))
    swal(
        i18n.t('sorry')+'!',
        i18n.t('error_editing'),
        "error"
    ).then((value) => {
      //props.history.push("/admin/users");
    })
    
  })
}



export const userUpdateEffect = (userformdata: Userformdata,id:number, props:any):Effect => async (
    dispatch
) => {
  dispatch(userRequest())

  delete userformdata.userName
  delete userformdata.matchingPassword
  delete userformdata.password

  API.patch("/users/"+id, userformdata)
      .then(response => {
        const { data } = response.data
        const user = data
       
        API.get('/users').then(response=>{
          dispatch(
              userSuccess(response.data)
          )
        })

        swal(
            "Done!",
            "User updated successfully ",
            "success"
        ).then(()=>{
          props.history.push("/admin/users/");
        })
      }).catch((err) => {
        const {response} = err
        dispatch(userError(response?.data))
        swal(
          "Sorry!",
          "An error occured while saving user",
          "error"
        ).then(()=>{
          
        })
        
    })
}

export const usergetEffect = ():Effect => async (
  dispatch
  ) => {
  dispatch(userRequest())
  
  API.get("/users")
  .then(response => {
    const data  = response.data
    dispatch( 
     userSuccess(data)
    )
  }).catch((err) => {
    const {response} = err
    dispatch(userError(response?.data))
    
  })
}




export const getProfileEffect = ():Effect => async (
  dispatch
  ) => {
  //dispatch(userRequest())
  
  API.get("/profils")
  .then(response => {
    const  data  = response.data
    const profile = data
    console.log(profile)
    dispatch( 
     getProfile(profile)
    )
  }).catch((err) => {
    getProfile([])
    
  })
}

export const getRolesEffect = ():Effect => async (
    dispatch
) => {
  //dispatch(userRequest())

  API.get("/roles")
      .then(response => {
        const  data  = response.data
        const roles = data
        console.log(roles)
        dispatch(
            getRoles(roles)
        )
      }).catch((err) => {
    getProfile([])

  })
}

export const getOneUserEffect = async (id:any) => {
        var response = await API.get("/users/"+id)
        return response.data
}

export const getOneProfileEffect = async (id:any) => {
  var response = await API.get("/profils/"+id)
  return response.data
}

export const delOneProfileEffect = async (id:any) => {
  swal({
    title: "Are you sure?",
    text: "Once deleted, you will not be able to recover this imaginary file!",
    icon: "warning",
    dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {
      swal("Poof! Your imaginary file has been deleted!", {
        icon: "success",
      });
    } else {
      swal("Your imaginary file is safe!");
    }
  });
  var response = await API.delete("/profils/"+id)
  return response
}





