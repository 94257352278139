import produce from 'immer';
import SecureLS from 'secure-ls';
import { Convert } from '../../../interfaces';
import { GET_SESSION } from '../../actions/AccountAction';
import { ApplicationAction } from '../../types';
import {  AccountState, LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS } from '../../types/AccountTypes'


const ls = new SecureLS({ encodingType: 'aes' });

let session  = ls.get('session') 

if (session !==undefined  && session !=='')
  session = Convert.toSession(session);
  
else
  session = {
    id:0,
    username: '',
    email:    '',
    profil: undefined,
    type:'',
    token:'',
    expireIn: undefined
  }

const initialState: AccountState = {
    session: session,
    loading: {
        login_loading: false,
        login_failed: false,
        login_failed_message: '',
    }
}



const reducer = (state = initialState, action: ApplicationAction) => {
    switch (action.type) {
      case LOGIN_REQUEST: {
        return produce(state, (draft) => {
          // Ensure we clear current session
          draft.session = undefined;

          draft.loading.login_loading = true;
          draft.loading.login_failed = false;
          draft.loading.login_failed_message = '';
        });
      }
  
      case LOGIN_SUCCESS: {
        const { session } = action;
        console.log(session)
        ls.set('session', (session===undefined)?"":Convert.sessionToJson(session));
        
        return produce(state, (draft) => {
          draft.session = session;

          draft.loading.login_loading = false;
          draft.loading.login_failed = false;
          draft.loading.login_failed_message = '';
         
        });
      }
  
      case LOGIN_FAILURE: {
        const { message } = action;
  
        return produce(state, (draft) => {
          draft.loading.login_loading = false;
          draft.loading.login_failed = true;
          draft.loading.login_failed_message = message;
        });
      }      

      default:
          return state
    }
}  

export default reducer