//import React from "react";

import React, { useState, lazy } from "react";

/// React router dom
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import ForgotPassword from "./pages/ForgotPassword";
/// Widget
import Widget from "./pages/Widget";

/// Deshboard
import Home from "./components/Dashboard/Home/Home";
/* import Companies from "./components/Dashboard/Companies/Companies";
import Analytics from "./components/Dashboard/Analytics/Analytics";
import Review from "./components/Dashboard/Review/Review";
import Order from "./components/Dashboard/Order/Order";
import Orderlist from "./components/Dashboard/Orderlist/Orderlist";
import Customerlist from "./components/Dashboard/Customerlist/Customerlist"; */
import Messages from "./components/Dashboard/Messages/Messages";
import Contacts from "./components/Dashboard/Contacts/Contacts";
import Kanban from "./components/Dashboard/Kanban/Kanban";
import Projects from "./components/Dashboard/Projects/Projects";
import Calendarpage from "./components/Dashboard/Calendarpage/Calendarpage";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiMediaObject from "./components/bootstrap/MediaObject";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";
/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Chart
import SparklineChart from "./components/charts/Sparkline";
import ChartFloat from "./components/charts/chartflot/index";
import ChartJs from "./components/charts/Chartjs";
import Chartist from "./components/charts/chartist";
import RechartJs from "./components/charts/rechart";
import BtcChart from "./components/charts/apexcharts/ApexChart";
import ApexChart from "./components/charts/apexcharts";

/// Table
import DataTable from "./components/table/DataTable";
import BootstrapTable from "./components/table/BootstrapTable";

/// Forms
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import SummerNote from "./components/Forms/Summernote/SummerNote";
import Pickers from "./components/Forms/Pickers/Pickers";
import jQueryValidation from "./components/Forms/jQueryValidation/jQueryValidation";

/// Pulgin
import Select2 from "./components/PluginsMenu/Select2/Select2";
import Nestable from "./components/PluginsMenu/Nestable/Nestable";
import MainNouiSlider from "./components/PluginsMenu/Noui Slider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/Sweet Alert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/Jqv Map/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";


// BGFI Views

import UserForm from "./components/bgfi/account/users/usersForm";
import UserDetails from "./components/bgfi/account/users/usersDetails";
import UserList from "./components/bgfi/account/users/usersList";
import RegionsList from "./components/bgfi/agencies/regions/regionsList";
import ProfilesList from "./components/bgfi/account/profiles/profilesList";
import ProfilesForm from "./components/bgfi/account/profiles/profilesForm";
import ProductsList from "./components/bgfi/products/products/productsList";
import ProductsForm from "./components/bgfi/products/products/productsForm";
import RangesForm from "./components/bgfi/products/ranges/rangesForm";
import RangesList from "./components/bgfi/products/ranges/rangesList";
import RegionsForm from "./components/bgfi/agencies/regions/regionsForm";
import AgenciesList from "./components/bgfi/agencies/agencies/agenciesList";
import AgenciesForm from "./components/bgfi/agencies/agencies/agenciesForm";

const Markup = () => {

   let path = window.location.pathname;
   path = path.split("/");
   path = path[path.length - 1];
 
   let pagePath = path.split("-").includes("page");

   const [activeEvent, setActiveEvent] = useState(!path);

   const routes = [
		/// Dashborad
		{ url: "/admin/", component: Home },
		{ url: "/admin/projects", component: Messages},
		{ url: "/admin/contacts", component:Contacts },
		{ url: "/admin/kanban", component: Kanban },
		{ url: "/admin/calendar", component: Calendarpage },
		{ url: "/admin/messages", component: Messages },
		
		/// Accounts
	    { url: "/admin/profiles", component: ProfilesList},
	    { url: "/admin/profiles/edit/:id", component: ProfilesForm},
	    { url: "/admin/profiles/create", component: ProfilesForm},

		{ url: "/admin/users/create", component: UserForm},
		{ url: "/admin/users/view/:id", component: UserDetails},
		{ url: "/admin/users/edit/:id", component: UserForm},
	    { url: "/admin/users", component: UserList},
		/// Agencies 
		{ url: "/admin/agencies", component: AgenciesList},
		{ url: "/admin/agencies/create", component: AgenciesForm},
		{ url: "/admin/agencies/edit/:id", component: AgenciesForm},

		{ url: "/admin/regions", component: RegionsList},
		{ url: "/admin/regions/create", component: RegionsForm},
		{ url: "/admin/regions/edit/:id", component: RegionsForm},
		
		/// products
		{ url: "/admin/products", component: ProductsList},
		{ url: "/admin/products/create", component: ProductsForm},
		{ url: "/admin/products/edit/:id", component: ProductsForm},

		{ url: "/admin/ranges", component: RangesList},
		{ url: "/admin/ranges/create", component: RangesForm},
		{ url: "/admin/ranges/edit/:id", component: RangesForm},


	    //{ url: "/admin/roles", component: UserList},
		//{ url: "/admin/companies", component: Companies },
		//{ url: "/admin/analytics", component: Analytics },
		//{ url: "/admin/review", component: Review },
		//{ url: "/admin/order", component: Order },
		//{ url: "/admin/order-list", component: Orderlist },
		//{ url: "/admin/customer-list", component: Customerlist },
		
		/// Bootstrap
		{ url: "/admin/ui-alert", component: UiAlert },
		{ url: "/admin/ui-badge", component: UiBadge },
		{ url: "/admin/ui-button", component: UiButton },
		{ url: "/admin/ui-modal", component: UiModal },
		{ url: "/admin/ui-button-group", component: UiButtonGroup },
		{ url: "/admin/ui-accordion", component: UiAccordion },
		{ url: "/admin/ui-list-group", component: UiListGroup },
		{ url: "/admin/ui-media-object", component: UiMediaObject },
		{ url: "/admin/ui-card", component: UiCards },
		{ url: "/admin/ui-carousel", component: UiCarousel },
		{ url: "/admin/ui-dropdown", component: UiDropDown },
		{ url: "/admin/ui-popover", component: UiPopOver },
		{ url: "/admin/ui-progressbar", component: UiProgressBar },
		{ url: "/admin/ui-tab", component: UiTab },
		{ url: "/admin/ui-pagination", component: UiPagination },
		{ url: "/admin/ui-typography", component: UiTypography },
		{ url: "/admin/ui-grid", component: UiGrid },
		/// Apps
		{ url: "/admin/app-profile", component: AppProfile },
		{ url: "/admin/post-details", component: PostDetails },
		{ url: "/admin/email-compose", component: Compose },
		{ url: "/admin/email-inbox", component: Inbox },
		{ url: "/admin/email-read", component: Read },
		{ url: "/admin/app-calender", component: Calendar },
		/// Shop
		{ url: "/admin/ecom-product-grid", component: ProductGrid },
		{ url: "/admin/ecom-product-list", component: ProductList },
		{ url: "/admin/ecom-product-detail", component: ProductDetail },
		{ url: "/admin/ecom-product-order", component: ProductOrder },
		{ url: "/admin/ecom-checkout", component: Checkout },
		{ url: "/admin/ecom-invoice", component: Invoice },
		{ url: "/admin/ecom-product-detail", component: ProductDetail },
		{ url: "/admin/ecom-customers", component: Customers },
	
		/// Chart
		{ url: "/admin/chart-sparkline", component: SparklineChart },
		{ url: "/admin/chart-float", component: ChartFloat },
		{ url: "/admin/chart-chartjs", component: ChartJs },
		{ url: "/admin/chart-chartist", component: Chartist },
		{ url: "/admin/chart-btc", component: BtcChart },
		{ url: "/admin/chart-apexchart", component: ApexChart },
		{ url: "/admin/chart-rechart", component: RechartJs },
	
		/// table
		{ url: "/admin/table-datatable-basic", component: DataTable },
		{ url: "/admin/table-bootstrap-basic", component: BootstrapTable },
	
		/// Forms
		{ url: "/admin/form-element", component: Element },
		{ url: "/admin/form-wizard", component: Wizard },
		{ url: "/admin/form-wizard", component: Wizard },
		{ url: "/admin/form-editor-summernote", component: SummerNote },
		{ url: "/admin/form-pickers", component: Pickers },
		{ url: "/admin/form-validation-jquery", component: jQueryValidation },

		/// Plugin

		{ url: "/admin/uc-select2", component: Select2 },
		{ url: "/admin/uc-nestable", component: Nestable },
		{ url: "/admin/uc-noui-slider", component: MainNouiSlider },
		{ url: "/admin/uc-sweetalert", component: MainSweetAlert },
		{ url: "/admin/uc-toastr", component: Toastr },
		{ url: "/admin/map-jqvmap", component: JqvMap },
		{ url: "/admin/uc-lightgallery", component: Lightgallery },

		/// pages
		{ url: "/admin/widget-basic", component: Widget },
		{ url: "/admin/page-register", component: Registration },
		{ url: "/admin/page-lock-screen", component: LockScreen },
		{ url: "/admin/page-login", component: Login },
		{ url: "/admin/page-error-400", component: Error400 },
		{ url: "/admin/page-error-403", component: Error403 },
		{ url: "/admin/page-error-404", component: Error404 },
		{ url: "/admin/page-error-500", component: Error500 },
		{ url: "/admin/page-error-503", component: Error503 },
		{ url: "/admin/page-forgot-password", component: ForgotPassword },
   ];

   return (
			<div
            id={`${!pagePath ? "main-wrapper" : ""}`}
            className={`${!pagePath ? "show" : "mh100vh"}`}
         >
            {!pagePath && (
               <Nav
                  onClick={() => setActiveEvent(!activeEvent)} activeEvent={activeEvent}
                  onClick2={() => setActiveEvent(false)}
                  onClick3={() => setActiveEvent(true)}
               />
            )}
            
            <div
               className={` ${!path && activeEvent ? "rightside-event" : ""} ${
                  !pagePath ? "content-body" : ""
               }`}
            >
               <div className={`${!pagePath ? "container-fluid" : ""}`}>
			   <Switch>
                     {routes.map((data, i) => {
                        return (
							<Route
							key={i}
							path={data.url}
							exact
							render={props => (
								<data.component {...props} />
							)} />
	  
						)
					})}
				  
                  </Switch>
               </div>
            </div>
            {!pagePath && <Footer />}
         </div>
    );
};

export default Markup;
