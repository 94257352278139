import produce from 'immer';
import SecureLS from 'secure-ls';
import {Convert, Session, User} from '../../../interfaces';
import { GET_SESSION } from '../../actions/AccountAction';
import { ApplicationAction } from '../../types';
import {  AccountState, GET_USER_POPROFILE, LOGIN_FAILURE, LOGIN_REQUEST, LOGIN_SUCCESS, UserState, USER_FAILURE, USER_REQUEST, USER_SUCCESS, GET_USERS } from '../../types/AccountTypes'
import {FormState} from "final-form";
import {FORM_REQUEST, FORM_STOP, LoadingFormState} from "../../types/RequestTypes";

const ls = new SecureLS({ encodingType: 'aes' });


const initialState: LoadingFormState = {

      form_loading: false,
      form_failed: false,
      form_failed_message: '',
}



const reducer = (state = initialState, action: ApplicationAction) => {
    switch (action.type) {
      case FORM_REQUEST: {
        return produce(state, (draft) => {
          draft.form_loading = true;
          draft.form_failed = false;
          draft.form_failed_message = '';
        });
      }
  
      case FORM_STOP: {

        return produce(state, (draft) => {
          draft.form_loading = false;
          draft.form_failed = false;
          draft.form_failed_message = '';
        });
      }

    
      default:
          return state
    }
}  

export default reducer