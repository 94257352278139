import React,{Fragment,useState} from "react";
import { Link } from "react-router-dom";
import {Modal} from 'react-bootstrap';
import Ratingpage from './Ratingpage';
import avatar from '../../../../../images/avatar/1.jpg';

const SingleProductList = (props) => {
   const {
      previewImg,
      price,
      rating,
      availability,
      productCode,
      brand,
   } = props.product;
   
   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
   return (
		<Fragment>
			<div className="col-lg-12 col-xl-6">
				 <div className="card">
					<div className="card-body">
					   <div className="row m-b-30">
						  <div className="col-md-5 col-xxl-12">
							 <div className="new-arrival-product mb-4 mb-xxl-4 mb-md-0">
								<div className="new-arrivals-img-contnent">
								   <img className="img-fluid" src={previewImg} alt="" />
								</div>
							 </div>
						  </div>
						  <div className="col-md-7 col-xxl-12">
							 <div className="new-arrival-content position-relative">
								<h4><Link to="/admin/ecom-product-detail" className="text-black">Solid Women's V-neck Dark T-Shirt</Link></h4>
								<div className="comment-review star-rating">
								   {rating}
								   <span className="review-text">(34 reviews) /</span>
								   <Link className="product-review"  onClick={handleShow} to="/admin/ecom-product-list">
									  {" "}
									  Write a review?
								   </Link>
									<p className="price">${price}</p>
								</div>
								
								<p>
								   Availability:{" "}
								   <span className="item">
									  {availability}
									  <i className="fa fa-check-circle text-success"></i>
								   </span>
								</p>
								<p>
								   Product code:{" "}
								   <span className="item">{productCode}</span>
								</p>
								<p> Brand: <span className="item">{brand}</span>  </p>
								<p className="text-content">
									There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even.
								</p>
							 </div>
						  </div>
					   </div>
					</div>
				 </div>
			</div>
			<Modal class="modal fade" show={show}  onHide={handleClose} id="reviewModal">
				<div  role="document">
					<div class="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Review</h5>
							<button type="button" className="close" onClick={handleClose} data-dismiss="modal"><span>&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<form>
								<div className="text-center mb-4">
									<img className="img-fluid rounded" width="78" src={avatar} alt="DexignLab" />
								</div>
								<div className="form-group ">
									<Ratingpage />
								</div>
								<div className="form-group">
									<textarea className="form-control" placeholder="Comment" rows="5"></textarea>
								</div>
									<button className="btn btn-success btn-block">RATE</button>
							</form>
						</div>
					</div>
				</div>
			</Modal>
		</Fragment>	
	);
};

export default SingleProductList;
