import { useState, useEffect,Fragment} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {
   Row,
   Col,
} from "react-bootstrap";

import { useTranslation } from 'react-i18next';
import PageTitle from "../../../../layouts/PageTitle";
import {
   getOneProductsEffect,
   getRangeEffect,
   productsAddEffect,
   productsUpdateEffect} from "../../../../../../store/effects/FormEffects";
import { Link } from 'react-router-dom';


const ProductsForm = (props) => {

   const { t} = useTranslation()
   const id = (props.match.params.id!== undefined && props.match.params.id !==null)?props.match.params.id:null;
   const dispatch = useDispatch()
   const userState = useSelector((state) => state.users)
   const rolesState = useSelector((state) => state.roles.roles)
   const formrequestState = useSelector((state) => state.formRequest)
   const [ranges, setRanges]  = useState([])
   const [productsFormData, setProductsFormData] = useState({
      label: "",
      definition: "",
      remuneration: 0,
      tarification: 0,
      sinister: "",
      guarantees: "",
      benefits: "",
      productRangeId: null
   });
   const [rolesFormData, setRolesFormData] = useState([])
   const handleBlur = (e) => {
      const newProductsFormData = { ...productsFormData };

      newProductsFormData[e.target.name] = e.target.value;
      setProductsFormData(newProductsFormData);
      
   };


   const svg1 = (
      <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
         <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
         </g>
      </svg>
   );

   const submitHandler = (event) => {
      const form = event.currentTarget;
      if (form.checkValidity()===false) {
         event.preventDefault()
         event.stopPropagation()
      }else{
         event.preventDefault();
         (id==null)?dispatch(productsAddEffect(productsFormData, props)):dispatch(productsUpdateEffect(productsFormData, id, props))
      }

      form.classList.add('was-validated');

   };


   useEffect(()=>{

      getRangeEffect().then(value => {
         setRanges(value)
      })

      if (id!==null){
         getOneProductsEffect(id).then((r)=>{
          
            const tmpgeted = { ...productsFormData };
            for (const [key, value] of Object.entries(r)) {

               if(key=='productRange')
                  tmpgeted['productRangeId']= value.id;
               else
                  tmpgeted[key]= value;
            }

            setProductsFormData(tmpgeted)
         }).catch(e=>{
            console.log('error', e)
         })
      }
   }, [])

   return (
      <div className="col-12">
      <PageTitle activeMenu="Edit" motherMenu="Products" />
         <div className="card">
            <div className="card-header">
              <div style={{width:"90%"}}>
                 <h3>Products Editing</h3>
              </div>
              <Link to="/admin/products" className="btn btn-primary btn-xs"><i className="fa fa-arrow-left"></i> Back</Link>
           
            </div>
            <div className="card-body pl-5">

               <Fragment>
                  
                  <Row>
                     <Col lg={6}>
                        <div className="basic-form">
                           <form class="row g-3 needs-validation" onSubmit={submitHandler} noValidate>
                              <div className="form-row">
                                 <div className="form-group col-md-12">
                                    <label>Label <span style={{color:"red"}}>*</span></label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       placeholder="Product label"
                                       onChange={handleBlur}
                                       value={productsFormData?.label}
                                       required
                                       name='label'
                                    />
                                    <div class="invalid-feedback">
                                       Please enter a label
                                    </div>
                                 </div>

                                 <div className="form-group col-md-12">
                                    <label>Category / range</label>
                                    <select className="form-control" name="productRangeId" onChange={handleBlur} >
                                       <option value=""> -- select --</option>
                                       {
                                          ranges != undefined && ranges.length > 0
                                          && ranges?.map((v,i)=>{
                                             console.log(v.id)
                                             console.log(productsFormData?.productRange)
                                             return <option value={v.id} selected={(id!==null && productsFormData?.productRangeId===v.id)?true:false}>{v.name}</option>
                                          })
                                       }
                                    </select>

                                 </div>

                                 <div className="form-group col-md-12">
                                    <label>Description <span style={{color:"red"}}>*</span></label>
                                    <textarea
                                       type="text"
                                       className="form-control"
                                       placeholder="product definition"
                                       value={productsFormData?.definition}
                                       name='definition'
                                       onChange={handleBlur}
                                    > </textarea>
                                    <div class="invalid-feedback">
                                       Please enter a definition
                                    </div>
                                 </div>
                                 <div className="form-group col-md-12">
                                    <label>Remuneration <span style={{color:"red"}}>*</span></label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="product remuneration"
                                        required
                                        value={productsFormData?.remuneration}
                                        min-value="0"
                                        name='remuneration'
                                        onChange={handleBlur}
                                    />
                                    <div className="invalid-feedback">
                                       Please enter a remuneration value
                                    </div>
                                 </div>

                                 <div className="form-group col-md-12">
                                    <label>Pricing <span style={{color:"red"}}>*</span></label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="product pricing"
                                        required
                                        value={productsFormData?.tarification}
                                        name='tarification'
                                        onChange={handleBlur}
                                    />
                                    <div className="invalid-feedback">
                                       Please enter a price
                                    </div>
                                 </div>

                                 <div className="form-group col-md-12">
                                    <label>Sinister <span style={{color:"red"}}>*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="sinister"
                                        required
                                        value={productsFormData?.sinister}
                                        name='sinister'
                                        onChange={handleBlur}
                                    />
                                    <div className="invalid-feedback">
                                       Please enter a value for sinister
                                    </div>
                                 </div>

                                 <div className="form-group col-md-12">
                                    <label>Guarantees </label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        name='guarantees'
                                        value={productsFormData?.guarantees}
                                        onChange={handleBlur}
                                    ></textarea>
                                    <div className="invalid-feedback">
                                       Please enter  guarantees details
                                    </div>
                                 </div>
                                 <div className="form-group col-md-12">
                                 <label>Benefits <span style={{color:"red"}}>*</span></label>
                                 <textarea
                                     type="text"
                                     className="form-control"
                                     placeholder="benefits"
                                     required
                                     value={productsFormData?.benefits}
                                     name='benefits'
                                     onChange={handleBlur}
                                 ></textarea>
                                 <div className="invalid-feedback">
                                    Please enter benefits details
                                 </div>
                              </div>

                              </div>
                              
                              <div className="form-group col-md-12">

                              </div>{
                              ()=>{console.log('userstate',userState )}
                           }
                              <button type="submit"  id="submit"  className="btn btn-primary">
                                 
                                 {

                                     (formrequestState.form_loading) ?<>
                                     <span class="spinner-grow spinner-grow-sm"  role="status" aria-hidden="true"></span>
                                     <span class=""> Loading... </span></> : <span>{id==null?'Create':'Save'} </span> 
                                 }
                              </button>
                           </form>
                        </div>
                     </Col>
                  </Row>
               </Fragment>
   
            </div>
         </div>
      </div>
   );
};

export default ProductsForm;
