import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from '../../../assets/images/logo-bgfi.png';

const ForgotPassword = () => {
   const [forgotData, setForgotData] = useState({});
   const handleBlur = (e) => {
      const newForgotData = { ...forgotData };
      newForgotData[e.target.name] = e.target.value;
      setForgotData(newForgotData);
   };

   const submitHandler = (e) => {
      e.preventDefault();
      
   };

   return (
      <div className="row justify-content-center  align-items-center h-80">
         <div className="col-md-6">
            <div className="authincation-content">
               <div className="row no-gutters">
                  <div className="col-xl-12">
                     <div className="auth-form">
						<div className="text-center mb-2">
							<div><Link to={"/"}><img src={logo} alt="" style={{padding:20,backgroundColor:'#fff', borderRadius:8}} /></Link></div>
						</div>
                        <h2 className="text-center mb-2 text-white"> EER Admin </h2>
                        <h4 className="text-center mb-2 text-white"> Forgot Password</h4>
                        <form action="./" onSubmit={(e) => e.preventDefault(submitHandler)}>
                           <div className="form-group">
                              <label className="mb-1 text-white"> <strong>Email</strong>  </label>
                              <input type="email" className="form-control" placeholder="hello@example.com" onChange={handleBlur}/>
                           </div>
                           <div className="form-row d-flex justify-content-end mt-4 mb-2">
                              
                              <div className="form-group">
                                 <Link  className="text-white"  to="/admin/admin//admin/login"  > Login  </Link>
                              </div>
                           </div>
                           <div className="text-center mt-4">
                              <button type="submit" className="btn btn-primary bg-white text-primary btn-block" onClick={() => submitHandler}>SUBMIT</button>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default ForgotPassword;
