import {Button, Col, Container, Form, FormControl, Nav, Navbar, NavDropdown, Row} from "react-bootstrap";
import React from "react";
import logo from '../../assets/images/logo-bgfi.png'


export default function NavigationBar() {
    
    return(

            <Navbar  className="headerNav navbar navbar-expand-sm"  sticky="top" bg="light" expand="md">

                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Brand href="/">
                    <img
                        src={logo}
                        height="55"
                        className="d-inline-block align-top"
                        alt="BGFIBank EER"
                    />
                </Navbar.Brand>

                <Navbar.Collapse id="basic-navbar-nav" className="mr-3">
                    <Nav className="mr-auto"></Nav>
                    <Nav className="ml-3 bgfi">
                        <Nav.Link href="https://cameroun.groupebgfibank.com/">Site Web</Nav.Link>
                        <Nav.Link href="https://cameroun.groupebgfibank.com/faq/">FAQ</Nav.Link>
                        <Nav.Link href="https://cameroun.groupebgfibank.com/contacts/">Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <Form inline>
                    <Button  className="btn btn-primary" variant="primary">Créer un compte</Button>
                </Form>
            </Navbar>

    )
}