import { ThunkAction, ThunkDispatch } from 'redux-thunk';

import SecureLS from 'secure-ls';
import { useHistory } from "react-router-dom";
import { useState } from 'react';
import { ApplicationAction, ApplicationState } from '../types';
import { API } from '../../components/services/api';
import swal from "sweetalert";
import {formRequest, formStop, getProfile, userError, userRequest, userSuccess} from '../actions/AccountAction';
import i18n from '../../translations/index'
const ls = new SecureLS({encodingType: 'aes'})

type Effect = ThunkAction<any, ApplicationState, any, ApplicationAction>;




export interface Profilformdata {
  "label": string,
  "description": string,
  "roles"?:number[]
}


export const profileAddEffect = (data: Profilformdata, rolesFormData:any, props:any):Effect => async (
    dispatch
) => {
    data.roles = rolesFormData
    dispatch(formRequest())
    API.post("/profils", data)
        .then(response => {
            dispatch(formStop())
            swal(
                i18n.t('done')+'!',
                i18n.t('success_editing'),
                "success"
            ).then(() => {
                props.history.push("/admin/profiles");
            })
        }).catch((err) => {
        const {response} = err
        dispatch(formStop())
        swal(
            i18n.t('sorry')+'!',
            i18n.t('error_editing'),
            "error"
        ).then(() => {
            //props.history.push("/admin/profiles");
        })

    })
}


export const rangeAddEffect = (data: any, props:any):Effect => async (
    dispatch
) => {
    dispatch(formRequest())

    API.post("/ranges", data)
        .then(response => {

            swal(
                i18n.t('done')+'!',
                i18n.t('success_editing'),
                "success"
            ).then((value) => {
                props.history.push("/admin/ranges");
            })
            dispatch(formStop())

        }).catch((err) => {
        const {response} = err
        dispatch(formStop())
        swal(
            i18n.t('sorry')+'!',
            i18n.t('error_editing'),
            "error"
        ).then((value) => {
            props.history.push("/admin/ranges");
        })

    })
}

export const rangeUpdateEffect = (data: any, id:number, props:any):Effect => async (
    dispatch
) => {
    dispatch(formRequest())

    API.patch("/ranges/"+id, data)
        .then(response => {

            swal(
                i18n.t('done')+'!',
                i18n.t('success_editing'),
                "success"
            ).then((value) => {
                props.history.push("/admin/ranges");
            })
            dispatch(formStop())

        }).catch((err) => {
        const {response} = err
        dispatch(formStop())
        swal(
            i18n.t('sorry')+'!',
            i18n.t('error_editing'),
            "error"
        ).then((value) => {
           
        })

    })
}

export const delOneRangeEffect = async (id:any) => {
    stopLoader()
    var response = await API.delete("/ranges/"+id)
    return response
  }
  
export const delOneProductsEffect = async (id:any) => {
    stopLoader()
    var response = await API.delete("/products/"+id)
    return response
  }
  

export const productsAddEffect = (data: any, props:any):Effect => async (
    dispatch
) => {
    dispatch(formRequest())

    API.post("/products", data)
        .then(response => {

            swal(
                i18n.t('done')+'!',
                i18n.t('success_editing'),
                "success"
            ).then((value) => {
                props.history.push("/admin/products");
            })

        }).catch((err) => {
        const {response} = err
        console.log('____',response)
        dispatch(formStop())
        swal(
            i18n.t('sorry')+'!',
            i18n.t('error_editing'),
            "error"
        ).then((value) => {
            //props.history.push("/admin/products");
        })

    })
}


export const productsUpdateEffect = (data: any,id:number, props:any):Effect => async (
    dispatch
) => {
    dispatch(formRequest())

    API.patch("/products/"+id, data)
        .then(response => {
            dispatch(formStop())
            swal(
                i18n.t('done')+'!',
                i18n.t('success_editing'),
                "success"
            ).then((value) => {
                props.history.push("/admin/products");
            })

        }).catch((err) => {
            dispatch(formStop())
        const {response} = err
        
        swal(
            i18n.t('sorry')+'!',
            i18n.t('error_editing'),
            "error"
        ).then((value) => {
            
        })
    })
}

export const profileUpdateEffect = (data: Profilformdata,rolesFormData:any,id:any, props:any):Effect => async (
    dispatch
) => {
    data.roles = rolesFormData
    dispatch(formRequest())
    API.patch("/profils/"+id, data)
        .then(response => {
            const { data } = response.data
            const user = data
            dispatch(
                formStop()
            )
            swal(
                i18n.t('done')+'!',
                i18n.t('success_editing'),
                "success"
            ).then((value) => {
                props.history.push("/admin/profiles");
            })

        }).catch((err) => {

        dispatch(formStop())
        swal(
            i18n.t('sorry')+'!',
            i18n.t('error_editing'),
            "error"
        ).then((value) => {
            //window.location.href = "/admin/profiles"
        })
    })
}

export const getOneRangeEffect = async (id: number) => {
    var  response = await API.get("/ranges/"+id)
    return response.data
}

export const getRangeEffect = async () => {
    var  response = await API.get("/ranges")
    return response.data
}

export const getProductsEffect = async () => {
    
    var  response = await API.get("/products")
    return response.data
}



export const regionsAddEffect = (data: any, props:any):Effect => async (
    dispatch
) => {
    dispatch(formRequest())
    API.post("/regions", data)
        .then(response => {
            dispatch(formStop())
            swal(
                i18n.t('done')+'!',
                i18n.t('success_editing'),
                "success"
            ).then(() => {
                props.history.push("/admin/regions");
            })
        }).catch((err) => {
        const {response} = err
        dispatch(formStop())
        swal(
            i18n.t('sorry')+'!',
            i18n.t('error_editing'),
            "error"
        ).then(() => {
            //props.history.push("/admin/profiles");
        })

    })
}

export const regionsUpdateEffect = (data: any, id:number, props:any):Effect => async (
    dispatch
) => {
    dispatch(formRequest())
    API.patch("/regions/"+id, data)
        .then(response => {
            dispatch(formStop())
            swal(
                i18n.t('done')+'!',
                i18n.t('success_editing'),
                "success"
            ).then(() => {
                props.history.push("/admin/regions");
            })
        }).catch((err) => {
        const {response} = err
        dispatch(formStop())
        swal(
            i18n.t('sorry')+'!',
            i18n.t('error_editing'),
            "error"
        ).then(() => {
            //props.history.push("/admin/profiles");
        })

    })
}

export const getOneProductsEffect = async (id:number) => {
    var  response = await API.get("/products/"+id)
    return response.data
}

export const getRegionsEffect = async () => {    
    var  response = await API.get("/regions")
    return response.data
}

export const getAgenciesEffect = async () => {    
    var  response = await API.get("/agencies")
    return response.data
}

export const getOneRegionsEffect = async (id:number) => {    
    var  response = await API.get("/regions/"+id)
    return response.data
}

export const delOneRegionsEffect = async (id:number) => {
    var  response = await API.delete("/regions/"+id)
    return response
}

export const stopLoader = ():Effect => async (
    dispatch
) => {
    dispatch(formRequest())
}


export const agenciesAddEffect = (data: any, address: any, props:any):Effect => async (
    dispatch
) => {
    dispatch(formRequest())

    API.post("/address", address)
        .then(response => {
            data['address'] = response.data.id
            delete data?.regions    
            if(data['address']>0){
                API.post("/agencies", data)
            .then(response => {
                dispatch(formStop())
                
                    swal(
                        i18n.t('done')+'!',
                        i18n.t('success_editing'),
                        "success"
                    ).then(() => {
                        props.history.push("/admin/agencies");
                    })
                })
            }else{
                dispatch(formStop())
                API.delete("/address/"+response.data.id) 
                swal(
                i18n.t('sorry')+'!',
                i18n.t('error_editing'),
                "error"
                ).then(() => {
                    
                })
            }
            
        }).catch((err) => {
        const {response} = err
        dispatch(formStop())
        swal(
            i18n.t('sorry')+'!',
            i18n.t('error_editing'),
            "error"
        ).then(() => {
            //props.history.push("/admin/profiles");
        })

    })
}

export const delOneAgenciesEffect = async (id:number) => {
    var  response = await API.delete("/agencies/"+id)
    return response
}
